import styled from "styled-components";
import Colors from "../../constants/colors"

const MultilineBlockContainer = styled.div`
  width: 100%;
  margin-bottom: 5%;
  margin: auto;
  text-align: center;
  display: flex;
  flex-direction: column;

  

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    flex-direction: row;
    width: 800px;
    align-items: center;

    > h3 {
      width: 350px;
      text-align: right;
      margin-top: 0;
      margin-bottom:0;
      margin-left: 0;
      margin-right: 40px;
    }

    > p {
      margin: 0;
      text-align: left;
      padding-left: 30px;
      border-left: 3px solid ${Colors.lightBlue};
    }
  }
`;

export { MultilineBlockContainer };
