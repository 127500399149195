import { ReactElement, useState } from "react";
import useMeasure from "react-use-measure";
import { useSpring } from "react-spring";
import { H3, Text, Line } from "../styled";
import {
  Container,
  Icon,
  Content,
  Heading,
  ContentContainer,
} from "./collabsible.styles";
import Plus from "../../assets/plus.svg";
import Minus from "../../assets/minus.svg";

type Props = {
  title: string;
  text: ReactElement<string>[];
};

const Collabsible = ({ title, text }: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [ref, bounds] = useMeasure();

  const contentAnimatedStyle = useSpring({
    height: isOpen ? bounds.height : 0,
  });

  return (
    <Container isOpen={isOpen}>
      <Heading onClick={() => setIsOpen(!isOpen)}>
        <H3>{title}</H3>
        <div>
          {isOpen ? (
            <Icon src={Minus} alt={"minus icon"} />
          ) : (
            <Icon src={Plus} alt={"plus icon"} />
          )}
        </div>
      </Heading>
      <Content style={contentAnimatedStyle}>
        <ContentContainer ref={ref}>
          <Line />
          {text?.map((T: ReactElement, i: number) => {
            return <Text>{T.props.children}</Text>;
          })}
        </ContentContainer>
      </Content>
    </Container>
  );
};

export default Collabsible;
