import AlbertWenger from "../images/quote-headshots/AlbertWenger.png";
import BillGates from "../images/quote-headshots/BillGates.png";
import ChamathPalihapitiya from "../images/quote-headshots/ChamathPalihapitiya.png";
import LarryFink from "../images/quote-headshots/LarryFink.png";
import UrsulaVonDerLeyen from "../images/quote-headshots/UrsulaVonDerLeyen.png";


const Quotes = [
  {
    text: "The world’s first trillionaire will be made in climate change.",
    person: {
      headshot: ChamathPalihapitiya,
      name: "Chamath Palihapitiya",
      title: "CEO, Social Capital",
    },
  },

  {
    text: "There will be eight Teslas, 10 Teslas….There will be, you know, Microsoft-, Google-, Amazon-type companies that come out of this space.",
    person: {
      headshot: BillGates,
      name: "Bill Gates",
      title: "Founder, Microsoft",
    },
  },

  {
  text: "I want this decade to be the Roaring Twenties of climate action and climate investment...it is a once-in-a-generation opportunity to become global leaders in the economy of the future.",
  person: {
    headshot: UrsulaVonDerLeyen,
    name: "Ursula von der Leyen",
    title: "President, European Commission",
    },
  },
  
  {
    text: "It is my belief that the next 1,000 unicorns — companies that have a market valuation over a billion dollars — won’t be a search engine, won’t be a media company, they’ll be businesses developing green hydrogen, green agriculture, green steel and green cement...",
    person: {
      headshot: LarryFink,
      name: "Larry Fink",
      title: "CEO, Blackrock",
    },
  },
  {
    text: "The transformation of all aspects of the physical world over the coming decades will be on par with the changes brought about by digital technology.",
    person: {
      headshot: AlbertWenger,
      name: "Albert Wenger",
      title: "Partner, Union Square Ventures",
    },
  },
];

export default Quotes;