import styled from "styled-components";
import Colors from "../../constants/colors";
import { CircleDiv, H3, H3Light } from "../styled";

type ThumbProps = {
  isActive?: boolean;
};

type ImageProps = {
  headshot: string;
};

const CarouselContainer = styled.div`
  width: 100%;
  margin-top: 10%;
  margin-bottom: 5%;
  overflow: hidden;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin-top: 10%;
    margin-bottom: 15%;
  }
`;

const Carousel = styled.div`
  display: flex;
  flex-direction: column;
`;

const QuoteCardContainer = styled.div`
  display: flex;
  background: #fff;
  margin: auto;
  align-items: center;
  border-radius: 5px;
  width: 90%;
  height: fit-content;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: fit-content;
    position: relative;

    height: 230px;
    margin-right: 135px;
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 30px;
  overflow: visible;
  word-wrap: normal
  hyphens: manual;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin-left: 115px;
    width: max-content;
    max-width: 500px;
  }
`;

const ImageContainer = styled(CircleDiv)`
  display none;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    display: inline-block;
    background: ${(props: ImageProps) => `url(${props.headshot})`};
    background-size: cover;
    position: absolute;
    width: 230px;
    height: 230px;
    top: 0;
    left: -115px;
  }
`;

const InlineImage = styled(CircleDiv)`
  background: ${(props: ImageProps) => `url(${props.headshot})`};
  background-size: cover;
  width: 60px;
  height: 60px;
  margin-right: 10px;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    display: none;
  }
`;
const InfoContainer = styled.div`
  display: flex;
`;

const Info = styled.div`
  display: flex;
  flex-direction: column;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: baseline;
  }
`;

const Name = styled(H3)`
  margin-bottom: 0;
  color: ${Colors.purple};
  font-size: 18px;
  margin-right: 10px;
`;

const Title = styled(H3Light)`
  margin-top: 0;
  font-size: 18px;
  margin-bottom: 0;
`;

const QuoteContainer = styled.div`
  margin: 0 auto;
  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    left: 115px;
    position: relative;
  }
`;

const Quote = styled(H3)`
  font-size: 20px;
`;

const ThumbContainer = styled.div`
  width: auto;
  min-width: 50%;
  max-width: 70%;
  display: flex;
  margin: auto;
  margin-top: 8%;
  margin-bottom: 20px;
  > div {
    justify-content: space-between;
  }

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    min-width: 40%;
    max-width: 50%;
    margin-top: 5%;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    min-width: 25%;
    max-width: 35%;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    min-width: 20%;
    max-width: 25%;
    margin-top: 5%;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    min-width: 10%;
    max-width: 20%;
  }
`;

const ThumbContainerTop = styled(ThumbContainer)`
  display: flex;
  margin-top: 20px;
  margin-bottom: 8%;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    display none;
  }
`;
const ThumbContainerBottom = styled(ThumbContainer)`
  display: none;
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    display flex;
  }
`;

const Thumb = styled.div`
  width: 10px;
  height: 10px;
  background-color: ${(props: ThumbProps) =>
    props.isActive ? `${Colors.text}` : "none"};
  border: 2px solid ${Colors.text};
  border-radius: 50%;

  &:hover {
    cursor: pointer;
    background-color: ${Colors.text};
    opacity: ${(props: ThumbProps) => (props.isActive ? 1 : 0.5)};
  }
`;

export {
  CarouselContainer,
  Carousel,
  QuoteCardContainer,
  Content,
  ImageContainer,
  Info,
  InfoContainer,
  Name,
  Title,
  Quote,
  QuoteContainer,
  Thumb,
  ThumbContainerTop,
  ThumbContainerBottom,
  InlineImage,
};
