import Insta from "../../assets/insta.svg";
import LinkedIn from "../../assets/linkedIn.svg";
import Twitter from "../../assets/twitter.svg";
import { PrimaryButton, SecondaryButton } from "../../components/button/button";
import Pill, { PillProps, PillValues } from "../../components/pill/pill";
import { Text, TextBold, Wrapper } from "../../components/styled";
import { H3 } from "../../components/styled";
import {
  Logo,
  NameContainer,
  Name,
  PillContainer,
  Socials,
  ContentContainer,
  PageContainer,
  InternalNavContainer,
  SocialIcon,
  InlinePreviewImage,
  InvestorWrapper,
  InvestorContainer,
  InvestorLogo,
} from "./profile.styles";
import {useParams, useNavigate} from "react-router-dom";
import CompanyCards from "../../constants/company-cards"
import {Helmet} from "react-helmet" // for OpenGraph and Page Titles
import FourOhFour from "../four-oh-four/fourOhFour"
import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom'

const Profile = () => {
  // Analytics
  ReactGA.send({ hitType: "pageview", page: useLocation().pathname });

  // gets the lowercased, one-worded from the URL
  const { name } = useParams()

  // finds the company card corresponding to that name
  const companyCard = CompanyCards.find((obj) => {
    return obj.name.replace(/\s/g, "").toLowerCase() === name;
  });

  if(companyCard === undefined) { // invalid company pathname
    return <FourOhFour />
  }

  return (
    <Wrapper>
      <Helmet>
        <title>{companyCard.name}'s Future List Profile</title>
        <meta property="og:title" content={companyCard.name + " selected for the 2022 Future List: Join Top Climate Tech Companies"} />
        <meta property="og:image" content={`${companyCard.ogLinkShareImage}`} />
        <meta property="og:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta property="og:url" content={"/profile/" + name} />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@future_list_co" />
        <meta name="twitter:title" content={companyCard.name + " selected for the 2022 Future List"} />
        <meta name="twitter:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta name="twitter:image" content={`${companyCard.ogLinkShareImage}`} />
      </Helmet>

      <PageContainer>
        <ContentContainer>
          <Logo image={companyCard.logo}/>
          <NameContainer>
            <Name>{companyCard.name}</Name>
            {/*<PrimaryButton text="I'm Interested" onClick={() => {
              window.open(
                `https://airtable.com/shr8x0ktLIQrvAAJ8?prefill_Companies+of+Interest=${companyCard.name}`,
                '_blank', 'noopener,noreferrer')
              }
            }/>*/}
          </NameContainer>
          <InlinePreviewImage image={companyCard.landingPage}/>

          <H3>Why It's a Great Time To Join</H3>
          <Text>
            <p>{companyCard.goodTimeToJoin}</p>
          </Text>

          <H3>{companyCard.inOwnWords ? 'In Their Own Words' : 'About'}</H3>
          <Text>
            {companyCard.description.map((paragraph: string, i: number) => {
              return <p>{paragraph}</p>;
            })}
            <br />
          </Text>

          <H3>Details</H3>
          <PillContainer>
            <Pill type="size" text={companyCard.size} />
            {companyCard.location?.map((l: string, i: number) => {
              return <Pill key={i} type="location" text={l} />;
            })}
          </PillContainer>
          <PillContainer>
            {companyCard.jobPills.map((p: PillProps, i: number) => {
              return <Pill key={i} {...p} />;
            })}
          </PillContainer>
          <PillContainer>
            <Pill type="stage" text={companyCard.stage} />
          </PillContainer>

          <InvestorWrapper>
            <H3>Investors</H3>
            <InvestorContainer>
              {companyCard.investorLogos.map((l: string, i:number) => {
                return <InvestorLogo key={i} image={l}/>;
              })}
            </InvestorContainer>
            <Text>{companyCard.investorNames}</Text>
          </InvestorWrapper>    
        </ContentContainer>
      </PageContainer>

    </Wrapper>
  );
};

export default Profile;
