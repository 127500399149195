import { H3Bold } from "../styled";
import {
  Card,
  CardsContainer,
  Container,
  ValueContainer,
  NumberContainer,
  Number,
  LabelText,
  HighlightText,
  Title,
  InfoContainer,
  DescriptionText,
} from "./companyHighlight.styles";
import { useSpring, config } from "react-spring";

type Props = {
  currency: string;
  amount: number;
  value: string;
  label: string;
  company: string;
  description: string;
  startAnimation: boolean;
};

const SimpleCompanyCard = ({
  currency,
  amount,
  value,
  label,
  company,
  description,
  startAnimation,
}: Props) => {
  const props = useSpring({
    val: amount,
    from: { val: 0 },
    config: config.molasses,
    reset: startAnimation,
  });
  return (
    <Card>
      <ValueContainer>
        <NumberContainer>
          <HighlightText>{currency}</HighlightText>
          <Number>{props.val.to((val) => Math.floor(val))}</Number>
          <HighlightText>{value}</HighlightText>
        </NumberContainer>
        <LabelText>{label}</LabelText>
      </ValueContainer>
      <InfoContainer>
        <Title>{company}</Title>
        <DescriptionText>{description}</DescriptionText>
      </InfoContainer>
    </Card>
  );
};

const CompanyHighlight = ({ title, companies, startAnimation }: any) => {
  return (
    <Container>
      <H3Bold>{title}</H3Bold>
      <CardsContainer>
        {companies?.map((c: Props, i: number) => {
          return (
            <SimpleCompanyCard key={i} {...c} startAnimation={startAnimation} />
          );
        })}
      </CardsContainer>
    </Container>
  );
};

export default CompanyHighlight;
