import {
  Container,
  ModalBackground
} from "./modal.styles";
import { H2 } from "../styled"
import { SecondaryButton } from "../button/button";
import ReactDOM from "react-dom";


interface ModalProps {
  show: boolean;
  title: string;
  close: any;
  children: any;
}

const Modal = ({ show, title, close, children}: ModalProps) => {
  return ReactDOM.createPortal(

    show &&
      <>
        <ModalBackground onClick={(e) => {
          e.stopPropagation(); // makes it so you can't click buttons beneath the background
          close()
        }}>
          <Container onClick={(e) => e.stopPropagation()}>
            {/*Prevents the event from hitting the background and closing the modal*/}
            <H2>{title}</H2>
            {children}
          </Container>
        </ModalBackground>
      </>
    ,

    // renders into index.html's modal component outside the root hierarchy
    document.getElementById("modal")! 
  );
};

export default Modal;