import {
  NavItem,
  MobileNavItem,
  NavItemContainer,
  NavLogo,
  MainNavItemGroup,
  MobileNavItemGroup,
  NavContainer,
  LinkElem,
  BackgroundColor,
  MobileNav,
  Hamburger,
  NavScreen,
  Icon,
} from "./nav.styles";
import { matchPath, useLocation } from "react-router";
import { useEffect, useState } from "react";
import { Wrapper } from "../styled";
import HamburgerIcon from "../../assets/hamburger.svg";
import HamburgerCloseIcon from "../../assets/hamburger-close.svg";
import { useSpring } from "react-spring";

const Nav = () => {
  const [isDarkMode, setIsDarkMode] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const location = useLocation();

  const contentAnimatedStyle = useSpring({
    height: isMenuOpen ? "100%" : "0%",
  });

  useEffect(() => {
    setIsDarkMode(!matchPath(location.pathname, "/"));
    isMenuOpen
      ? document.body.classList.add("no-scroll")
      : document.body.classList.remove("no-scroll");
    return () => {
      document.body.classList.remove("no-scroll");
    };
  }, [isDarkMode, location, isMenuOpen]);

  return (
    <BackgroundColor isDarkMode={isDarkMode}>
      <Wrapper>
        <NavContainer>
          <LinkElem to="/" isDarkMode={isDarkMode}>
            <NavLogo isDarkMode={isDarkMode}>Future List</NavLogo>
          </LinkElem>
          <MainNavItemGroup>
            <NavItemContainer>
              <LinkElem to="/" isDarkMode={isDarkMode}>
                <NavItem isDarkMode={isDarkMode}>Home</NavItem>
              </LinkElem>
            </NavItemContainer>
            <NavItemContainer>
              <LinkElem to="/why" isDarkMode={isDarkMode}>
                <NavItem isDarkMode={isDarkMode}>Why Climate?</NavItem>
              </LinkElem>
            </NavItemContainer>
            <NavItemContainer>
              <LinkElem to="/faq" isDarkMode={isDarkMode}>
                <NavItem isDarkMode={isDarkMode}>FAQ</NavItem>
              </LinkElem>
            </NavItemContainer>
            <NavItemContainer>
              <NavItem
                isDarkMode={isDarkMode}
                onClick={() =>
                  (window.location.href =
                    "mailto:futurelistco@gmail.com?subject=Learning%20more%20about%20Future%20List&body=Tell%20us%20about%20yourself")
                }>
                Contact
              </NavItem>
            </NavItemContainer>
          </MainNavItemGroup>
          <MobileNav>
            <Hamburger
              onClick={() => {
                setIsMenuOpen(true);
              }}>
              <Icon
                src={HamburgerIcon}
                alt={"hamburger menu icon"}
                isDarkMode={isDarkMode}
              />
            </Hamburger>
          </MobileNav>
        </NavContainer>
      </Wrapper>
      <NavScreen isDarkMode={isDarkMode} style={contentAnimatedStyle}>
        <Wrapper>
          <NavContainer>
            <LinkElem
              to="/"
              isDarkMode={isDarkMode}
              onClick={() => setIsMenuOpen(false)}>
              <NavLogo isDarkMode={isDarkMode}>Future List</NavLogo>
            </LinkElem>
            <Hamburger onClick={() => setIsMenuOpen(false)}>
              <Icon
                src={HamburgerCloseIcon}
                alt={"Close menu icon"}
                isDarkMode={isDarkMode}
              />
            </Hamburger>
          </NavContainer>
          <MobileNavItemGroup>
            <LinkElem
              to="/"
              isDarkMode={isDarkMode}
              onClick={() => setIsMenuOpen(false)}>
              <MobileNavItem isDarkMode={isDarkMode}>Home</MobileNavItem>
            </LinkElem>
            <LinkElem
              to="/why"
              isDarkMode={isDarkMode}
              onClick={() => setIsMenuOpen(false)}>
              <MobileNavItem isDarkMode={isDarkMode}>Why Climate?</MobileNavItem>
            </LinkElem>
            <LinkElem
              to="/faq"
              isDarkMode={isDarkMode}
              onClick={() => setIsMenuOpen(false)}>
              <MobileNavItem isDarkMode={isDarkMode}>FAQ</MobileNavItem>
            </LinkElem>
            <MobileNavItem
              isDarkMode={isDarkMode}
              onClick={() =>
                (window.location.href =
                  "mailto:futurelistco@gmail.com?subject=Learning%20more%20about%20Future%20List&body=Tell%20us%20about%20yourself")
              }>
              Contact
            </MobileNavItem>
          </MobileNavItemGroup>
        </Wrapper>
      </NavScreen>
    </BackgroundColor>
  );
};

export default Nav;
