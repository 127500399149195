import { H1 } from "../../components/styled";
import { PrimaryButton } from "../button/button";
import { Container } from "./cta.styles";

type Props = {
  buttonText: string;
  callToAction: string;
  onClick: Function;
};

const CTA = ({ buttonText, callToAction, onClick}: Props) => {
  return (
    <Container>
      <H1>{callToAction}</H1>
      <PrimaryButton 
        text={buttonText} 
        onClick={onClick}
      />
    </Container>
  );
};

export default CTA;
