import styled from "styled-components";
import Colors from "../../constants/colors";

const ModalBackground = styled.div`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;

  z-index: 2;

  display: flex;
  justify-content: center;
  align-items: center;

  background-color: rgba(0,0,0, 0.35);

  opacity: 1;
`;

const Container = styled.div`
  background-color: ${Colors.background};
  padding: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  width: 250px;


  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 500px;
  }
`;

export {
  ModalBackground,
  Container
};