import {
  PrimaryBtnText,
  SecondaryBtnText,
  SecondaryBtnContainer,
  InlineBtnText,
  Button,
  Arrow,
  ArrowContainer,
} from "./button.styles";
import ArrowLeft from "../../assets/arrowLeft.svg";
import ArrowRight from "../../assets/arrowRight.svg";

type PrimaryButtonProps = {
  onClick?: Function;
  text: string;
};

type SecondaryButtonProps = {
  arrowLeft?: boolean;
  arrowRight?: boolean;
  onClick?: Function,
  text: string;
};

type InlineButtonProps = {
  text: string;
  onClick?: Function;
}

export const PrimaryButton = ({ text, onClick }: PrimaryButtonProps) => {
  return (
    <Button onClick={() => onClick && onClick()}>
      {/* Add in React Router Link or an onClick funcition to make these buttons functional  */}
      <PrimaryBtnText>{text}</PrimaryBtnText>
    </Button>
  );
};

export const SecondaryButton = ({
  arrowLeft,
  arrowRight,
  onClick,
  text,
}: SecondaryButtonProps) => {
  return (
    <SecondaryBtnContainer>
      {arrowLeft && (
        <ArrowContainer>
          <Arrow src={ArrowLeft} alt="arrow left" />
        </ArrowContainer>
      )}
      {/* Add in React Router Link or an onClick function to make these buttons functional  */}
      <SecondaryBtnText onClick={() => onClick && onClick()}>{text}</SecondaryBtnText>
      {arrowRight && (
        <ArrowContainer isRight={true}>
          <Arrow src={ArrowRight} alt="arrow right" />
        </ArrowContainer>
      )}
    </SecondaryBtnContainer>
  );
};

export const InlineButton = ({ text, onClick }: InlineButtonProps) => {
  return (
      <InlineBtnText onClick={() => onClick && onClick()}>{text}</InlineBtnText>
  );
};
