import { H3, Text } from "../styled";
import { MultilineBlockContainer } from "./titleMultilineTextBlock.styles";

type Props = {
  title: string;
  textLines: any[]; // list of components
  footer: any; // any final component
};

const TitleMultilineTextBlock = ({ title, textLines, footer}: Props) => {
  return (
    <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
      <MultilineBlockContainer>
        <H3>{title}</H3>
        <Text>{textLines}</Text>
      </MultilineBlockContainer>
      <div style={{marginTop: "30px"}}>
        {footer}
      </div>
    </div>
  );
};

export default TitleMultilineTextBlock;
