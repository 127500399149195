import styled from "styled-components";
import Colors from "../../constants/colors";

const SubstackIframe = styled.iframe`
	background: Colors.background;
	height: 250px;

	// for small screens
	width: 250px;

	// Large devices (desktops, 992px and up)
	@media (min-width: 992px) {
		width: 480px;
	}
`;

export {
	SubstackIframe
};