import CTA from "../../components/cta/cta"
import { useNavigate, useLocation } from "react-router-dom";
import ReactGA from "react-ga4"


const FourOhFour = () => {
    ReactGA.send({ hitType: "pageview", page: useLocation().pathname });

	let navigate = useNavigate();
	return <CTA
            callToAction="Page Not Found :("
            buttonText="Return to List"
            onClick={
            	() => {navigate("/", { replace: true });}
            }
          />
};

export default FourOhFour;
