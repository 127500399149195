import { 
  HeadingContainer,
  ListContainer,
  ListItem,
  ReasonContainer,
  GraphImage,
} from "./whyClimate.styles";

import { H1, H2, H3, Wrapper, Text} from "../../components/styled";
import { Helmet } from "react-helmet" // for OpenGraph and Page Titles

import MarketSizeGraph from "../../images/graphs/marketsize.png"
import VentureInvestmentGraph from "../../images/graphs/ventureinvestment.png"
import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom'




const WhyClimate = () => {
  // Analytics
  ReactGA.send({ hitType: "pageview", page: useLocation().pathname });


  const Reasons = [
    {
      heading: "A market opportunity worth trillions",
      bullets: [
        "The causes of climate change are fundamentally humanity’s need for energy (to power and move things) and food (to sustain itself). These are the largest markets in human history.",
        "Companies built in those spaces regularly reach tens of billions or more in revenue and correspondingly high valuations. Companies on Future List are tackling those precise markets.",
        "For context, software, which is only a $700B market, gave birth to three trillion-dollar valuation companies (Microsoft, Google, and Meta) and countless companies valued at $10B+.",
      ],
      graph: MarketSizeGraph,
    },
    {
      heading: "Excellent, de-risked opportunity for financial upside",
      bullets: [
        "Climate as an industry is now well-capitalized. Companies are regularly reaching the growth stage, where the likelihood of success and an exit is significantly higher.",
        "Because of how large the market opportunity is, there is still substantial upside for employees who join these companies now.",
        "Joining a de-risked company with lots of headroom for equity appreciation is one of the best risk-reward tradeoffs you can make.",
      ],
      graph: VentureInvestmentGraph,
    },
    {
      heading: "Innovative, impactful work while building a foundation for lasting success",
      bullets: [
        "Climate change will force unprecedented evolution in the way we live and work. Fighting a planet-scale threat with cutting-edge technology is rewarding, fulfilling work.",
        "Climate change will be humanity’s largest priority this century. Despite that, climate as an industry is still young. Getting in now gives you a head start to be a leader in the space.",
        "Climate companies work on interdisciplinary problems that offer unmatched opportunity for intellectual enrichment. You’ll work with professionals from many different industries and backgrounds, and the problem space is vast.",
      ],
      graph: null,
    },
  ]


  return (
    <Wrapper>

      <Helmet>
        <title>Future List: Join Top Climate Companies</title>
        <meta property="og:title" content="Future List: Join Top Climate Companies" />
        <meta property="og:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png"/>
        <meta property="og:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta property="og:url" content="/why" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@future_list_co" />
        <meta name="twitter:title" content="Future List: Join Top Climate Companies" />
        <meta name="twitter:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta name="twitter:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png" />
      </Helmet>

      <HeadingContainer>
        <H1>Climate is this century's largest market opportunity.</H1>
        <H2>
          Large markets are the cradle of tomorrow's valuable companies.<br/>
          Do innovative, meaningful work with great risk-adjusted upside.
        </H2>
      </HeadingContainer>
      <ListContainer>
        {Reasons.map((r, i) => {
          return (
            <>
              <ListItem>
                <ReasonContainer>
                  <H3>{r.heading}</H3>
                  <Text>
                    <ul>
                      {r.bullets.map((b, i) => { 
                        return (
                          <>
                          <li>{b}</li><br/>
                          </>
                        );
                      })} 
                    </ul>
                  </Text>
                </ReasonContainer>
              </ListItem>
              <ListItem>
                {(() => {
                  if (r.graph){
                    return <GraphImage image={r.graph} />
                  }
                })()}
              </ListItem>
            </>
          );
        })}

      </ListContainer>
    </Wrapper>
  );
};

export default WhyClimate;
