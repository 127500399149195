import Job from "../../assets/job.svg";
import Location from "../../assets/location.svg";
import Employee from "../../assets/employee.svg";
import Stage from "../../assets/stage.svg";
import Design from "../../assets/design.svg";
import Engineer from "../../assets/engineer.svg";
import { Container, Icon, PillText, TextContainer } from "./pill.styles";

export enum PillValues {
  noIcon = "noIcon",
  job = "job",
  location = "location",
  size = "size",
  stage = "stage",
  engineer = "engineer",
  design = "design",
}

export type PillType =
  | "noIcon"
  | "job"
  | "location"
  | "size"
  | "stage"
  | "engineer"
  | "design";

export interface PillProps {
  type: PillType;
  text: string;
}

const Pill = ({ type, text }: PillProps) => {
  const isAccent =
    type === PillValues.job ||
    type === PillValues.engineer ||
    type === PillValues.design ||
    type === PillValues.noIcon;
  var icon;
  if (type === PillValues.location) {
    icon = Location;
  } else if (type === PillValues.size) {
    icon = Employee;
  } else if (type === PillValues.stage) {
    icon = Stage;
  } else if (type === PillValues.engineer) {
    icon = Engineer;
  } else if (type === PillValues.design) {
    icon = Design;
  } else {
    icon = Job;
  }

  return (
    <Container isAccent={isAccent}>
      <div>{type !== PillValues.noIcon && <Icon src={icon} alt={icon} />}</div>
      <TextContainer>
        <PillText isAccent={isAccent}>{text}</PillText>
      </TextContainer>
    </Container>
  );
};

export default Pill;
