import styled from "styled-components";
import Colors from "../../constants/colors";

interface Props {
  isRight?: boolean;
}

const Button = styled.button`
  background: ${Colors.orange};
  border: 2px solid ${Colors.orange};
  border-radius: 3px;
  cursor: pointer;
  text-align: center;
  padding: 12px;
  min-width: 215px;

  &:hover {
    background: transparent;
    border: 2px dashed ${Colors.orange};
    > p {
      color: ${Colors.orange};
    }
  }
`;

const PrimaryBtnText = styled.p`
  font-family: SplineSansMono-Regular;
  margin: 0;
  font-weight: 700;
  font-size: 14px;
  color: ${Colors.white};

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const SecondaryBtnContainer = styled.div`
  height: 25px;
  margin: 10px 5px;
  display: flex;
  align-items: center;
`;

const SecondaryBtnText = styled.p`
  font-family: SplineSansMono-Regular;
  margin-top: 0.5em;
  font-weight: 700;
  font-size: 14px;
  color: ${Colors.orange};
  height: max-content;

  &:hover {
    cursor: pointer;
    border-bottom: 2px dashed ${Colors.orange};
    padding-bottom: 0px;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const InlineBtnText = styled.span`
  color: ${Colors.orange};

  &:hover {
    cursor: pointer;
    border-bottom: 2px dashed ${Colors.orange};
    padding-bottom: 0px;
  }
`;

const Arrow = styled.img`
  height: 20px;
  width: auto;
`;

const ArrowContainer = styled.div<Props>`
  ${(props) => (props.isRight ? "margin-left: 5px;" : "margin-right: 5px;")};
  margin-bottom: 3px;
`;

export {
  PrimaryBtnText,
  SecondaryBtnText,
  SecondaryBtnContainer,
  InlineBtnText,
  Button,
  Arrow,
  ArrowContainer,
};
