import styled from "styled-components";
import Colors from "../../constants/colors";
import { H1, Label, Text, TextBold } from "../styled";
import { animated } from "react-spring";

const Card = styled.div`
  height: 250px;
  width: 100%;
  background: linear-gradient(180deg, #ffffff 0%, #f0f0f0 100%);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  text-align: center;
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
  align-items: center;

  // medium devices (a little larger to allow for 2 cards)
  @media (min-width: 700px) {
    width: 350px;
    min
  }
`;

const CardsContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    justify-content: space-between;
  }
`;

const Container = styled.div`
  margin-top: 10%;
  margin-bottom: 10%;
`;

const Title = styled(TextBold)`
  font-size: 18px;
  margin-bottom: 0;
`;
const ValueContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  height: 50%;
  margin: 2.5% auto;
`;

const NumberContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: baseline;
`;

const HighlightText = styled(H1)`
  color: ${Colors.green};
  margin: 0;
  text-transform: none;
`;
const Number = styled(animated.h1)`
  font-family: Oswald-Bold;
  font-size: 50px;
  color: ${Colors.green};
  margin-bottom: 0;
`;

const LabelText = styled(Label)`
  font-size: 14px;
  margin-bottom: 0;
`;

const InfoContainer = styled.div`
  display: flex;
  height: 30%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 2.5% auto;
`;

const DescriptionText = styled(Text)`
  font-size: 16px;
  margin: 0;
`;

export {
  Card,
  CardsContainer,
  Container,
  Title,
  ValueContainer,
  NumberContainer,
  Number,
  LabelText,
  HighlightText,
  InfoContainer,
  DescriptionText,
};
