import styled from "styled-components";
import Colors from "../constants/colors";
import "../App.css";

const Wrapper = styled.div`
  margin-right: 1em;
  margin-left: 1em;

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    // width: calc(100%-1em);
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    width: 85%;
    margin-left: auto;
    margin-right: auto;
  }
`;

const H1 = styled.h1`
  font-family: Oswald-Bold;
  margin-top: 0.5em;
  font-size: 30px;
  color: ${Colors.text};
  text-transform: uppercase;
  text-align: center;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 50px;
  }
`;

const H2 = styled.h2`
  font-family: SplineSansMono-Regular;
  margin-top: 0.5em;
  font-size: 18px;
  font-weight: 100;
  color: ${Colors.text};
  text-align: center;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const H3 = styled.h3`
  font-family: Oswald-Regular;
  margin-top: 0.5em;
  font-size: 20px;
  color: ${Colors.text};

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const H3Bold = styled.h3`
  font-family: Oswald-Bold;
  margin-top: 0.5em;
  font-size: 20px;
  color: ${Colors.text};
  text-transform: uppercase;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const H3Light = styled.h3`
  font-family: Oswald-Light;
  margin-top: 0.5em;
  font-size: 20px;
  color: ${Colors.text};
  font-weight: 100;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 24px;
  }
`;

const Text = styled.p`
  font-family: PTSans-Regular;
  margin-top: 0.5em;
  margin-bottom: 5px;
  font-size: 14px;
  color: ${Colors.text};

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 18px;
  }
`;

const TextBold = styled.p`
  font-family: PTSans-Bold;
  margin-top: 0.5em;
  font-size: 14px;
  color: ${Colors.text};

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 14px;
  }
`;

const Label = styled.p`
  font-family: Oswald-Bold;
  font-size: 12px;
  color: ${Colors.text};
  text-transform: uppercase;
  text-align: center;
  margin: 0;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    font-size: 16px;
  }
`;

const Line = styled.div`
  border: none;
  border-top: 2px dashed ${Colors.text};
  color: ${Colors.text};
  width: 100%;
  margin-bottom: 10px;
`;

const CircleDiv = styled.div`
  display: inline-block;
  background-position: 50% 50%;
  border-radius: 50%;
`;

export {
  Wrapper,
  H1,
  H2,
  H3,
  H3Bold,
  H3Light,
  Text,
  TextBold,
  Label,
  Line,
  CircleDiv,
};
