import React, {Component} from "react";
import ReactDOM from "react-dom/client";
import "./App.css";
import Landing from "./pages/landing/landing";

import { BrowserRouter, Route, Routes } from "react-router-dom";
import Nav from "./components/nav/nav";
import FAQ from "./pages/faq/faq";
import WhyClimate from "./pages/why-climate/whyClimate";
import FourOhFour from "./pages/four-oh-four/fourOhFour";
// import ComingSoon from "./pages/coming-soon/comingSoon";
import Profile from "./pages/profile/profile";

class App extends Component {
  render() {
    return (
      <React.StrictMode>
        <BrowserRouter>
          {/* Comment out when displaying coming soon page */}
          <Nav />
          <Routes>
            <Route path="/" element={<Landing />} />
            <Route path="faq" element={<FAQ />} />
            <Route path="why" element={<WhyClimate />} />
            <Route path="profile/:name" element={<Profile />} />
            <Route path="*" element={<FourOhFour />} />
          </Routes>
        </BrowserRouter>
      </React.StrictMode>
    );
  }
}

export default App;
