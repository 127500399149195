import styled from "styled-components";

const HeadingContainer = styled.div`
  margin-top: 10%;
  margin-bottom: 10%;
`;

const ListContainer = styled.div``;

export { HeadingContainer, ListContainer };
