import {
  CarouselContainer,
  Carousel,
  QuoteCardContainer,
  Content,
  ImageContainer,
  InlineImage,
  InfoContainer,
  Info,
  Name,
  Title,
  QuoteContainer,
  Quote,
  ThumbContainerTop,
  ThumbContainerBottom,
  Thumb,
} from "./quoteCarousel.styles";
import {
  useSpringCarousel,
  useSpringCarouselContext,
} from "react-spring-carousel";
import { useEffect, useState } from "react";

interface Person {
  headshot: string;
  name: string;
  title: string;
}

interface QuoteType {
  person: Person;
  text: string;
}

type CarouselProps = {
  quotes: QuoteType[];
};

type ThumbProps = {
  id: string;
  func: () => void;
};

const CarouselThumb = ({ id, func }: ThumbProps) => {
  const { useListenToCustomEvent, getIsActiveItem } =
    useSpringCarouselContext<"fixed">();
  const [isActive, setIsActive] = useState(id === "item-0");
  useListenToCustomEvent((event) => {
    // Triggered when the slide animation is completed
    if (event.eventName === "onSlideChange") {
      setIsActive(getIsActiveItem(id));
    }
  });
  return <Thumb onClick={func} isActive={isActive} />;
};

const QuoteCard = ({ text, person }: QuoteType) => {
  return (
    <QuoteCardContainer>
      <ImageContainer headshot={person.headshot} />
      <Content>
        <Quote>{text}</Quote>
        <InfoContainer>
          <InlineImage headshot={person.headshot} />
          <Info>
            <Name>{person.name}</Name>
            <Title>{person.title}</Title>
          </Info>
        </InfoContainer>
      </Content>
    </QuoteCardContainer>
  );
};

const QuoteCarousel = ({ quotes }: CarouselProps) => {
  const { carouselFragment, slideToNextItem, thumbsFragment, slideToItem } =
    useSpringCarousel({
      withLoop: true,
      initialActiveItem: 0,
      withThumbs: true,
      items: quotes?.map((q: QuoteType, i: number) => {
        return {
          id: `item-${i}`,
          slideType: "fixed",
          renderItem: (
            <QuoteContainer>
              <QuoteCard key={i} {...q} />
            </QuoteContainer>
          ),
          renderThumb: (
            <CarouselThumb
              id={`item-${i}`}
              func={() => slideToItem(`item-${i}`)}
            />
          ),
        };
      }),
    });

  useEffect(() => {
    const timer = setInterval(() => {
      slideToNextItem();
    }, 10000);
    return () => {
      window.clearInterval(timer);
    };
  }, [slideToNextItem, slideToItem]);

  return (
    <CarouselContainer>
      <ThumbContainerTop>{thumbsFragment}</ThumbContainerTop>
      <Carousel>{carouselFragment}</Carousel>
      <ThumbContainerBottom>{thumbsFragment}</ThumbContainerBottom>
    </CarouselContainer>
  );
};

export default QuoteCarousel;
