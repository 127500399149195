import ReactGA from "react-ga4"
import { useLocation } from 'react-router-dom';

import { HeadingContainer, ListContainer } from "./faq.styles";
import { InlineButton } from "../../components/button/button";

import { H1, Wrapper } from "../../components/styled";
import Collabsible from "../../components/collapsible/collabsible";
import {Helmet} from "react-helmet" // for OpenGraph and Page Titles
import PreviewFrame from "../../images/PreviewFrame.png"
import { useNavigate } from 'react-router-dom';


const FAQ = () => {
  const navigate = useNavigate();

  const QuestionsList = [
    {
      question: "What is the Future List?",
      answerLines: [
        <p>
          The Future List is a curated list of{" "}
          <span style={{ fontWeight: "bold" }}>
            innovative technology companies
          </span>{" "}
          solving the world’s most valuable problem:{" "}
          <span style={{ fontWeight: "bold" }}>climate change.</span>
        </p>,
        <p>
          All companies featured are growing on{" "}
          <span style={{ fontWeight: "bold" }}>breakout trajectories</span>,
          offering employees tremendous opportunity for{" "}
          <span style={{ fontWeight: "bold" }}>
            learning, impact, and upside.
          </span>
        </p>,
        <p>Formerly known as the Cool List.</p>,
      ],
    },
    {
      question: "Why should I join a company on the Future List?",
      answerLines: [
        <p>Because it's a incredible <span style={{ fontWeight: "bold" }}>career opportunity.</span></p>,
        <p>You'll have the chance to get in at the ground floor of an industry operating in a <span style={{ fontWeight: "bold" }}>multi-trillion-dollar</span> market.</p>,
        <p>This affords rare opportunity for <span style={{ fontWeight: "bold" }}>professional growth and financial upside</span>, all while doing <span style={{ fontWeight: "bold" }}>impactful, innovative work.</span></p>,
        <p> 
          Read more about 
          <InlineButton
            text={" why you should work in climate"}
            onClick={() => 
              navigate("/why")
            }
          />
          {"."}
        </p>,
      ],
    },
    {
      question:
        "What does it take for a company to be featured on the Future List?",
      answerLines: [
        <p>We vetted these businesses like investors would. Select criteria include:</p>,
        <p>&bull; Credible team</p>,
        <p>&bull; Viable, innovative technology</p>,
        <p>&bull; Actively hiring, or likely to soon be hiring, for a diverse set of roles</p>,
        <p>&bull; Addressing an enormous market opportunity</p>,
        <p>We’ve additionally
          evaluated their alignment with OneEarth’s{" "}
          <span style={{ fontStyle: "italic" }}>
            <InlineButton
              text={"Achieving the Paris Climate Agreement Goals (APCAG)"}
              onClick={() =>
                (window.location.href =
                  "https://www.oneearth.org/apcag-executive-briefing")
              }
            />
          </span>{" "}
          climate model.
        </p>,
      ],
    },
    {
      question: "Who’s behind the Future List, and why should I trust you?",
      answerLines: [
        <p>
          We’re an independent coalition of investors, engineers, recruiters,
          environmentalists, and climate technologists.
        </p>,
        <p>
          We care deeply about diverting driven, talented professionals into the
          most valuable, impactful, innovative industry of the century.
        </p>,
        <p>Why you should trust us:</p>,
        <p>
          &bull; <span style={{ fontWeight: "bold" }}>Informed</span>: we’ve
          read the research cover-to-cover. We have strong ideas about the
          sectors and companies where impact can be made and value can be
          created
        </p>,
        <p>
          &bull; <span style={{ fontWeight: "bold" }}>Data-Driven</span>: we
          aggregate data from investors, environmentalists, and climate tech
          experts to identify outstanding companies
        </p>,
        <p>
          &bull; <span style={{ fontWeight: "bold" }}>Unbiased</span>: we don’t
          accept payment in exchange for spots on Future List
        </p>,
        <p>
          &bull; <span style={{ fontWeight: "bold" }}>Proven</span>: we have a
          track record at making great picks
        </p>,
      ],
    },
    {
      question: "How do I get my company on the Future List?",
      answerLines: [
        <p>We’d love to meet you and learn about your business.</p>,
        <p>
          <InlineButton
            text={"Drop us a note."}
            onClick={() =>
              (window.location.href =
                "mailto:futurelistco@gmail.com?subject=Getting%20featured%20on%20Future%20List")
            }
          />
        </p>,
        <p>We guarantee a response.</p>,
      ],
    },
    {
      question: "Can I nominate a company to be on the Future List?",
      answerLines: [
        <p>Absolutely.</p>,
        <p>
          <InlineButton
            text={"Drop us a note and share a bit about the company you'd like to nominate."}
            onClick={() =>
              (window.location.href =
                "mailto:futurelistco@gmail.com?subject=Nominating%20company_name_here%20for%20Future%20List")
            }
          />
        </p>,
        <p>We guarantee a response.</p>,
      ],
    },
  ];

  ReactGA.send({ hitType: "pageview", page: useLocation().pathname });

  return (
    <Wrapper>
      <Helmet>
        <title>Future List: Join Top Climate Companies</title>
        <meta property="og:title" content="Future List: Join Top Climate Companies" />
        <meta property="og:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png"/>
        <meta property="og:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta property="og:url" content="/faq" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@future_list_co" />
        <meta name="twitter:title" content="Future List: Join Top Climate Companies" />
        <meta name="twitter:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta name="twitter:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png" />
      </Helmet>
      
      <HeadingContainer>
        <H1>FAQ</H1>
      </HeadingContainer>
      <ListContainer>
        {QuestionsList.map((q, i) => {
          return (
            <Collabsible key={i} title={q.question} text={q.answerLines} />
          );
        })}
      </ListContainer>
    </Wrapper>
  );
};

export default FAQ;
