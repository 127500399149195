const ListHighlightCompanies = [
  {
    company: "Li-Cycle",
    currency: "$",
    amount: 667,
    value: "mm",
    label: "raised",
    description: "first featured at Series C, now Public",
  },
  {
    company: "Rivian",
    currency: "$",
    amount: 66,
    value: "B",
    label: "Valuation at IPO",
    description: "first featured as a private company",
  },
  {
    company: "Built Robotics",
    currency: "$",
    amount: 112,
    value: "mm",
    label: "raised",
    description: "first featured at Series B",
  },
  {
    company: "Aurora Solar",
    currency: "$",
    amount: 523,
    value: "mm",
    label: "raised",
    description: "first featured at Series C",
  },
  {
    company: "Saildrone",
    currency: "$",
    amount: 189,
    value: "mm",
    label: "raised",
    description: "first featured at Series B",
  },
  {
    company: "Antora Energy",
    currency: "$",
    amount: 51,
    value: "mm",
    label: "raised",
    description: "first featured at Seed",
  },
];

export default ListHighlightCompanies;