import Pill, { PillValues } from "../components/pill/pill";

// company logo image imports
// must be a square, transparent .png
import BlocPower from "../images/company-logos/blocpower.png";
import Span from "../images/company-logos/span.png";
import LilacSolutions from "../images/company-logos/lilacsolutions.png";
import ElectricHydrogen from "../images/company-logos/electrichydrogen.png";
import DandelionEnergy from "../images/company-logos/dandelionenergy.png";
import Turntide from "../images/company-logos/turntide.png";
import Twelve from "../images/company-logos/twelve.png";
import RedwoodMaterials from "../images/company-logos/redwoodmaterials.png";
import FormEnergy from "../images/company-logos/formenergy.png";
import PerfectDay from "../images/company-logos/perfectday.png";
import PivotBio from "../images/company-logos/pivotbio.png";
import Arcadia from "../images/company-logos/arcadia.png";
import EnergyVault from "../images/company-logos/energyvault.png";
import Monolith from "../images/company-logos/monolith.png"

// company landing page image imports
// Targeting roughly a 1000 wide x 570 high px image
// Must be a rectangular .png, with a 1.75 width: height ratio
import BlocPowerLanding from "../images/company-landing-pages/blocpowerlanding.png";
import SpanLanding from "../images/company-landing-pages/spanlanding.png";
import LilacSolutionsLanding from "../images/company-landing-pages/lilacsolutionslanding.png";
import ElectricHydrogenLanding from "../images/company-landing-pages/electrichydrogenlanding.png";
import DandelionEnergyLanding from "../images/company-landing-pages/dandelionenergylanding.png";
import TurntideLanding from "../images/company-landing-pages/turntidelanding.png";
import TwelveLanding from "../images/company-landing-pages/twelvelanding.png";
import UpsideFoodsLanding from "../images/company-landing-pages/upsidefoodslanding.png";
import RedwoodMaterialsLanding from "../images/company-landing-pages/redwoodmaterialslanding.png";
import VoltusLanding from "../images/company-landing-pages/voltuslanding.png";
import FormEnergyLanding from "../images/company-landing-pages/formenergylanding.png";
import PerfectDayLanding from "../images/company-landing-pages/perfectdaylanding.png";
import PivotBioLanding from "../images/company-landing-pages/pivotbiolanding.png";
import ArcadiaLanding from "../images/company-landing-pages/arcadialanding.png";
import EnergyVaultLanding from "../images/company-landing-pages/energyvaultlanding.png";
import StemLanding from "../images/company-landing-pages/stemlanding.png";
import ProterraLanding from "../images/company-landing-pages/proterralanding.png";
import AmyrisLanding from "../images/company-landing-pages/amyrislanding.png";
import MonolithLanding from "../images/company-landing-pages/monolithlanding.png";


// investor logo image imports
// must be a square, transparent, WELL-PADDED .png
import A16Z from "../images/investor-logos/a16z.png";
import Kapor from "../images/investor-logos/kapor.png";
import Microsoft from "../images/investor-logos/microsoft.png";
import FifthWall from "../images/investor-logos/fifthwall.png";
import Wellington from "../images/investor-logos/wellington.png";
import Lowercarbon from "../images/investor-logos/lowercarbon.png";
import BreakthroughEnergy from "../images/investor-logos/breakthroughenergy.png";
import NEA from "../images/investor-logos/nea.png";
import GV from "../images/investor-logos/gv.png";
import DCVC from "../images/investor-logos/dcvc.png";
import Threshold from "../images/investor-logos/threshold.png";
import Norwest from "../images/investor-logos/norwest.png";
import Softbank from "../images/investor-logos/softbank.png";
import Temasek from "../images/investor-logos/temasek.png";
import TRowePrice from "../images/investor-logos/troweprice.png";
import Activate from "../images/investor-logos/activate.png";
import Coatue from "../images/investor-logos/coatue.png";
import BoxGroup from "../images/investor-logos/boxgroup.png";
import G2VP from "../images/investor-logos/g2vp.png";
import Tiger from "../images/investor-logos/tiger.png";
import JPMorgan from "../images/investor-logos/jpmorgan.png";
import Blackrock from "../images/investor-logos/blackrock.png";
import KleinerPerkins from "../images/investor-logos/kleinerperkins.png";
import Greylock from "../images/investor-logos/greylock.png";
import TPG from "../images/investor-logos/tpg.png";
import Khosla from "../images/investor-logos/khosla.png";
import Idealab from "../images/investor-logos/idealab.png";
import PrimeMoversLab from "../images/investor-logos/primemoverslab.png";
import Helena from "../images/investor-logos/helena.png";


// company opengraph preview image imports
// See here for source material
// https://docs.google.com/presentation/d/1TcZRxixQUcMk7d74MSob6IDaLIXKjSqGLDr_Q8nEFf0/edit#slide=id.g1369177cbb9_0_945 
// link format is: https://futurelist-preview-assets.web.app/company-opengraph-images/ogamyris.png
const BlocPowerOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogblocpower.png";
const SpanOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogspan.png";
const LilacSolutionsOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/oglilacsolutions.png";
const ElectricHydrogenOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogelectrichydrogen.png";
const DandelionEnergyOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogdandelionenergy.png";
const TurntideOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogturntide.png";
const TwelveOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogtwelve.png";
const UpsideFoodsOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogupsidefoods.png";
const RedwoodMaterialsOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogredwoodmaterials.png";
const VoltusOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogvoltus.png";
const FormEnergyOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogformenergy.png";
const PerfectDayOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogperfectday.png";
const PivotBioOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogpivotbio.png";
const ArcadiaOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogarcadia.png";
const EnergyVaultOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogenergyvault.png";
const StemOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogstem.png";
const ProterraOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogproterra.png";
const AmyrisOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogamyris.png";
const MonolithOG = "https://futurelist-preview-assets.web.app/company-opengraph-images/ogmonolith.png";

/* Template for how data is represented for the cards and profile pages. 
 * Assume any given piece of data might appear on card AND profile page.
{
  name: "ZeroAvia",

  // the name of the company logo .png imported above
  logo: ZeroAvia, 

  // name of the company landing page image imported above
  landingPage: ZeroAviaLanding,

  // name of the opengraph preview image imported above
  ogLinkShareImage:ZeroAviaLanding,

   // individual pills for each. Should be US-based locations that they're hiring
  location: ["New York, NY", "San Francisco, CA"],

  // individual pill
  size: "200-400 employees", 

  // sexy one-liner
  oneLiner: "ZeroAvia offers a zero-emission hydrogen powertrain for airplanes Their goal is to make aviation more sustainable by cutting down on costs and CO2 emissions.",
  
  // long-form description for profile page. Each item in the list is a paragraph.
  description: [],

  // is the description in their own words? If not, then we wrote it.
  // affects the label that appears on the profile page
  inOwnWords: false,

  // a paragraph on why it's a good time to join the company
  goodTimeToJoin: "ZeroAvia is positioned...",

  // just the most noteworthy investor names.
  investorNames: "a16z, ...",

  // investorImages
  investorLogos: [],

  // the main job categories they're hiring for (1-2 top ones)
  // Char limit of 15ish before you have to truncate. 
  // Good example: "Software, Data + 6 more"
  // Bad example: "Engineering, Design, + 4 more"
  jobType: "Engineering, Design, Product",

  // other job types that we can include in a hover 
  // e.g. Engineering + 8 others, where the 8 others are in a little tooltip on tap/hover
  otherJobType: [],

  // these will appear on the profile page
  // each pill accepts two properties: a type (specified in pill.tsx) and text
  jobPills: [
    { type: PillValues.engineer, text: "Software Engineer" },
    { type: PillValues.design, text: "UX Designer" },
    { type: PillValues.job, text: "Sales" },
  ],
  stage: "Series A", // E.g. Seed, Series A, Series B, Public, etc.
}
*/

/*
A comprehensive list of all job types and associated pills
for easy copy-pasting

    otherJobType: [
      'Hardware Engineering',
      'Software Engineering',
      'Systems Engineering',
      'Data Engineering',
      'Manufacturing Eng.',
      'Data Science',
      'Analytics',
      'QA',
      'Energy Services',
      'Construction',
      'Supply Chain',
      'R&D',
      'IT & Security',

      'Marketing & Comms',
      'Design',
      'Product',

      'HR & People',
      'Sales & BD',
      'Accounting & Finance',
      'BizOps',
      'Partnerships',
      'Legal',
    ],
    jobPills: [
      { type: PillValues.engineer, text: "Hardware Eng." },
      { type: PillValues.engineer, text: "Software Eng." },
      { type: PillValues.engineer, text: "Systems Eng." },
      { type: PillValues.engineer, text: "Data Eng." },
      { type: PillValues.engineer, text: "Manufacturing Eng." },
      { type: PillValues.engineer, text: "Data Sci." },
      { type: PillValues.engineer, text: "Analytics" },
      { type: PillValues.engineer, text: "QA" },
      { type: PillValues.engineer, text: "Energy Services" },
      { type: PillValues.engineer, text: "Construction" },
      { type: PillValues.engineer, text: "Supply Chain" },
      { type: PillValues.engineer, text: "R&D" },
      { type: PillValues.engineer, text: "IT & Security" },

      { type: PillValues.design, text: "Marketing & Comms" },
      { type: PillValues.design, text: "Design" },
      { type: PillValues.design, text: "Product" },

      { type: PillValues.job, text: "HR & People" },
      { type: PillValues.job, text: "Sales & BD" },
      { type: PillValues.job, text: "Acctg. & Finance" },
      { type: PillValues.job, text: "BizOps" },
      { type: PillValues.job, text: "Partnerships" },
      { type: PillValues.job, text: "Legal" },
    ],

*/

const CompanyCards = [

  // Dandelion Energy
  {
    name: "Dandelion Energy",
    logo: DandelionEnergy,
    landingPage:DandelionEnergyLanding,
    ogLinkShareImage:DandelionEnergyOG,
    location: ["New York", "Massachusetts", "Connecticut"],
    size: "51-200 employees",
    oneLiner: "The future of home geothermal: affordable, passive heating and cooling",
    description: [
      "Dandelion Energy's mission is liberate homes from fossil fuels.",
      "We offer products and services that allow people to power their homes with renewable energy. We believe that homes without fuels are cleaner, safer, and better for families, communities and planet Earth.",
      "Originally conceived at Google X, Alphabet’s innovation lab, Dandelion is now an independent company offering geothermal heating and cooling systems to homeowners.  As the nation's leading home geothermal company, Dandelion Energy helps homeowners free their homes from fossil fuels.  By making geothermal heating and cooling systems accessible and affordable for modern homeowners, Dandelion is working to mitigate climate change with renewable technologies that decarbonize homes. Today, Dandelion's heating and cooling solutions allow homeowners to save up to 50 percent on their heating and cooling bills and help the environment by moving away from conventional systems to reduce homes' carbon dioxide emissions by up to 80 percent. The state-of-the-art geothermal heat pump system offers wifi-enabled monitoring, creating a smarter, more responsive, and more innovative approach than any other HVAC system on the market.",
      "Dandelion started in 2017 in Westchester County, NY with a single warehouse.  Today, it has grown to over 200 employees and 5 warehouses in NY, CT and MA with venture backing from Breakthrough Energy, Lennar and Google Ventures to name a few.  We have installed almost 1000 geothermal systems in homes to date which has eliminated over 364,000 tons of CO2 being released into the air over the lifetime of those systems.",
      "Company Values: Put customers first; Bring curiosity; Make yourself, your team, Dandelion better every day; Be obsessed with safety; Build Mission Pride",
    ],
    inOwnWords: true,
    goodTimeToJoin: "Adoption of heat pumps is a key accelerant to enable \
                    complete electrification of home infrastructure. This \
                    is critical to reducing the 40%+ of carbon emissions \
                    associated with heating and cooling. Dandelion is \
                    one of the first movers in the space and is an industry \
                    leader for electrifying suburban areas - where 14%+ of \
                    Americans live.",
    investorNames: "New Enterprise Associates, GV, Breakthrough Energy Ventures",
    investorLogos: [NEA, GV, BreakthroughEnergy],
    jobType: "Mechanic + 4 more",
    otherJobType: [
      'Geothermal Designer',
      'HVAC Technician/Installer',
      'Sheet Metal Crew',
      'Excavation'
    ],
    jobPills: [
      { type: PillValues.engineer, text: "Mechanic" },
      { type: PillValues.engineer, text: "Geothermal Designer" },
      { type: PillValues.engineer, text: "HVAC Technician/Installer" },
      { type: PillValues.engineer, text: "Sheet Metal Crew" },
      { type: PillValues.engineer, text: "Excavation" },
    ],
    stage: "Series B1",
  },

  // Form Energy
  {
    name: "Form Energy",
    logo: FormEnergy,
    landingPage:FormEnergyLanding,
    ogLinkShareImage:FormEnergyOG,
    location: ["Weirton, WV", "Berkeley, CA", "Pittsburgh, PA", "Somerville, MA"],
    size: "201-500 employees",
    oneLiner: "The future of energy storage: multi-day, long-duration, grid storage",
    description: [
      "Form Energy is developing a new class of cost-effective, multi-day energy storage systems to enable a reliable and fully-renewable electric grid year-round. Their first commercial product is an iron-air battery for the electric grid.",
      "Their team is composed of passionate people who are deeply motivated and inspired to create a better world. They are supported by leading investors who share a common belief that low-cost, multi-day energy storage is a key enabler of tomorrow’s zero-carbon electric system.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Energy production is responsible for the second-largest share of greenhouse gas emissions. As power generation shifts to renewables in the coming decades, energy storage will be a crucial element of new, green electrical infrastructure. Form Energy’s iron-air battery costs less than a tenth of lithium batteries, making it a leading player in a rapidly-burgeoning space.",
    investorNames: "Breakthrough Energy Ventures, Coatue Management, Temasek, TPG",
    investorLogos: [BreakthroughEnergy, Coatue, Temasek, TPG],
    jobType: "R&D + 17 more",
    otherJobType: [
      'Project Engineering',
      'Battery Module Engineering',
      'Compliance & Certification',
      'Engineering Program Management',
      'Reliability & Test',
      'Systems Engineering',
      'Factory Engineering, Design, Construction',
      'Manufacturing Engineering',
      'New Product Introduction',
      'Manufacturing Operations',
      'Environmental, Health, and Safety',
      'Facilities Operations',
      'Finance',
      'People Operations',
      'Supply Chain',
      'Quality',
      'R&D',
      'Software Engineering'
    ],
    jobPills: [
      { type: PillValues.engineer, text: 'Project Engineering'},
      { type: PillValues.engineer, text: 'Battery Module Engineering'},
      { type: PillValues.job, text: 'Compliance & Certification'},
      { type: PillValues.engineer, text: 'Engineering Program Management'},
      { type: PillValues.engineer, text: 'Reliability & Test'},
      { type: PillValues.engineer, text: 'Systems Engineering'},
      { type: PillValues.engineer, text: 'Factory Engineering, Design, Construction'},
      { type: PillValues.engineer, text: 'Manufacturing Engineering'},
      { type: PillValues.engineer, text: 'New Product Introduction'},
      { type: PillValues.engineer, text: 'Manufacturing Operations'},
      { type: PillValues.engineer, text: 'Environmental, Health, and Safety'},
      { type: PillValues.engineer, text: 'Facilities Operations'},
      { type: PillValues.job, text: 'Finance'},
      { type: PillValues.job, text: 'People Operations'},
      { type: PillValues.job, text: 'Supply Chain'},
      { type: PillValues.engineer, text: 'Quality'},
      { type: PillValues.engineer, text: 'R&D'},
      { type: PillValues.engineer, text: 'Software Engineering'},
    ],
    stage: "Series E",
  },

  // Electric Hydrogen
  {
    name: "Electric Hydrogen",
    logo: ElectricHydrogen,
    landingPage:ElectricHydrogenLanding,
    ogLinkShareImage:ElectricHydrogenOG,
    location: ["SF Bay Area", "Massachusetts", "Texas", "Remote"],
    size: "201-500 employees",
    oneLiner: "The future of fuel: electrolyzer technologies to enable clean, abundant, and low-cost manufacture of hydrogen",
    description: [
      "Electric Hydrogen’s mission is to engineer the end of fossil fuel dependency by unlocking cost-competitive, fossil-free hydrogen, with a founding team that hails from First Solar and Tesla.",
      "The company is creating a new generation of electrolyzer technologies to enable clean, abundant, and low-cost hydrogen to end dependence on fossil fuels. They offer a solution to meet demand for fossil-free hydrogen at scale through a system with a uniquely integrated electrolysis plant, which innovates at the cell-level and extends through the full system design. They build systems designed for constructability with modular systems that reduce project cost and prevent schedule overruns.",
      "They describe their team/culture as: passionate do-gooders, daringly innovative, stronger together than separate, eager to try, fail and learn, non-hierarchical in truth and ideas, open and honest. They are hiring across all functions: many types of engineering, manufacturing, sales, operations, and more.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Hydrogen is an energy-rich fuel that burns clean, \
                    producing only energy and water vapor; experts agree \
                    that it represents a viable fuel alternative for major \
                    carbon-emitting transportation uses (e.g. ocean freight) \
                    and industrial uses that cannot yet be electrified. \
                    By starting early, Electric Hydrogen is well positioned \
                    to become for the hydrogen industry what the oil majors \
                    have been for the fossil fuel industry,",
    investorNames: "Breakthrough Energy Ventures, Fifth Wall, Temasek",
    investorLogos: [BreakthroughEnergy, FifthWall, Temasek],
    jobType: "Manufacturing + 18 more",
    otherJobType: [
      'Cell Catalyst',
      'Cell Testing',
      'Corporate Development',
      'Corporate Operations',
      'Deployment',
      'Finance',
      'IT',
      'Legal',
      'Manufacturing Engineering',
      'Manufacturing Operations',
      'New Product Integration',
      'People',
      'Power Systems Engineering',
      'Product Engineering',
      'Product Management',
      'Program Management',
      'Sales',
      'Software Engineering',
      'Test Engineering',
    ],
    jobPills: [
      { type: PillValues.engineer, text: 'Cell Catalyst'},
      { type: PillValues.engineer, text: 'Cell Testing'},
      { type: PillValues.job, text: 'Corporate Development'},
      { type: PillValues.job, text: 'Corporate Operations'},
      { type: PillValues.engineer, text: 'Deployment'},
      { type: PillValues.job, text: 'Finance'},
      { type: PillValues.job, text: 'IT'},
      { type: PillValues.engineer, text: 'Legal'},
      { type: PillValues.engineer, text: 'Manufacturing Engineering'},
      { type: PillValues.engineer, text: 'Manufacturing Operations'},
      { type: PillValues.engineer, text: 'New Product Integration'},
      { type: PillValues.job, text: 'People'},
      { type: PillValues.engineer, text: 'Power Systems Engineering'},
      { type: PillValues.engineer, text: 'Product Engineering'},
      { type: PillValues.engineer, text: 'Product Management'},
      { type: PillValues.engineer, text: 'Program Management'},
      { type: PillValues.job, text: 'Sales'},
      { type: PillValues.engineer, text: 'Software Engineering'},
      { type: PillValues.engineer, text: 'Test Engineering'},
    ],
    stage: "Series C",
  },

   // Twelve
  {
    name: "Twelve",
    logo: Twelve,
    landingPage:TwelveLanding,
    ogLinkShareImage:TwelveOG,
    location: ["Berkeley, CA", "Alameda, CA", "Moses Lake, WA"],
    size: "201-500 employees",
    oneLiner: "The future of fuel: use carbon from the air to make raw materials and jet fuel",
    description: [
      "Twelve was founded to change how the world's most critical chemicals, materials and fuels are manufactured. Their novel processes create these materials using air, not fossil fuels, with proprietary carbon transformation technology. They partner with leading companies to implement their processes at scale.",
      "Twelve was founded out of the Jaramillo Group at Stanford University, a recognized world leader in carbon dioxide electrocatalysis.",
      "The company comprises a growing team of electrochemists, material scientists, and engineers with cutting-edge expertise in the field of CO2 electrocatalysis and electrochemical reactor design, scouted from the best programs in the world. They are hiring in many functions: from business development and finance to product, manufacturing, and R&D roles.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Chemical and fuel manufacture in the status quo \
                    is extremely fossil-fuel intensive. The market \
                    opportunity to displace legacy chemical manufacturing \
                    with non-emitting methods is enormous: chemicals are \
                    a $4T market globally. Twelve is a clear pioneer in \
                    the space, and is poised for massive growth if they \
                    capture even a small sliver of the opportunity ahead.",
    investorNames: "DCVC",
    investorLogos: [DCVC],
    jobType: "Tech. Development + 5 more",
    otherJobType: [
      "Finance",
      "IT",
      "People",
      "Plant Development",
      "Supply Chain",
      "Technology Development"
    ],
    jobPills: [
      { type: PillValues.job, text: "Finance"},
      { type: PillValues.job, text: "IT"},
      { type: PillValues.job, text: "People"},
      { type: PillValues.engineer, text: "Plant Development"},
      { type: PillValues.job, text: "Supply Chain"},
      { type: PillValues.engineer, text: "Technology Development"},
    ],
    stage: "Series B",
  },

  // Span IO
  {
    name: "SPAN",
    logo: Span,
    landingPage:SpanLanding,
    ogLinkShareImage:SpanOG,
    location: ["San Francisco, CA", "Washington, DC", "Dallas, TX", "Atlanta, GA", "Remote", "Milwaukee, WI"],
    size: "201-500 employees",
    oneLiner: "The future of home electricity: smart home electrical panels with optional battery backups",
    description: [
      "At SPAN, our goal is to simplify the adoption of clean energy solutions by building products that not only set new standards for home electrification but can be used in every single home. As more distributed energy resources like solar and storage come onto the grid, it’s increasingly important to be able to improve the technology that sits between the grid, the home, and those distributed energy resources. SPAN’s smart panel replaces the electrical panel to become the center for connected power in the home.",
      "SPAN was founded in 2018. SPAN Founder and CEO Arch Rao was previously the Head of Products for the Tesla Energy business where he helped develop and launch flagship products like the Powerwall, Powerpack, and Solar Roof. Through his experience, he saw that the electrical panel was frequently the limiting factor in the user experience as well as the scaling of residential renewable energy projects. In 2018, Arch left Tesla and SPAN was born.",
      "SPAN has experienced rapid growth in the last 4 years. SPAN recently closed its Series B for $90m in funding, and is continuing to grow both its R&D for future product roadmap as well as the commercial expansion of sales for its SPAN Panel smart electrical panel and SPAN Drive EV charger. SPAN has installed customers in 42+ US states and recently launched installations in Puerto Rico. SPAN has 160+ employees and is continuing to add talent to our team as we become one of the leading fully integrated hardware and software companies in Climate Technology. SPAN is backed by leading investors in the clean energy space, including Amazon Alexa Fund, Fifth Wall, Footprint Coalition, Hardware Club, Wellington Management, and Wells Fargo Strategic Capital.",
      "At SPAN, we believe in having a safe and productive work environment that enables innovation and creativity. We maintain an environment of flexibility with the expectation that we all contribute to and impact our work and business through high performance. We believe that coming together enables us to openly share ideas, challenge each other and build a great company.",
      "Our Values: PURSUE EXCELLENCE WITH AUDACITY, TENACIOUSLY EXECUTE WITH EMPATHY, PROMOTE TRANSPARENCY WITH ACCOUNTABILITY, CONTINUOUSLY IMPROVE WITH INTENTION",
      "The team is growing across the board. SPAN is looking for smart individuals who are passionate about the renewable energy space! We are seeking Software, Device Software, Hardware and R&D Engineers to help build our awesome products! SPAN is also looking for Security Engineers and a Product Manager. In pursuit of excellence, we value openly sharing information, challenging each other, and promoting the best ideas.",
      "Join SPAN if you want to join a team that is enabling electrification for all and is quickly becoming one of the premier hardware & software integrated product companies in Climate Technology.",
    ],
    inOwnWords: true,
    goodTimeToJoin: "The electrified home can not only offer cost savings and \
                    optimize electrical usage during outages, but will eventually \
                    feed back into the grid as a distributed energy resource. \
                    Enabling the creation of microgrids and a future of distributed \
                    energy resources is key to a renewables-driven energy future. \
                    SPAN is growing fast as the leader in this mission.",
    investorNames: "Fifth Wall, Wellington Management",
    investorLogos: [FifthWall, Wellington],
    jobType: "Hardware Eng. + 6 more",
    otherJobType: [
      'Hardware Engineering',
      'Legal',
      'Manufacturing',
      'Product',
      'R&D',
      'Sales & Business Development',
      'Software Engineering',
      'Systems Engineering'
    ],
    jobPills: [
      { type: PillValues.engineer, text: 'Hardware Engineering'},
      { type: PillValues.engineer, text: 'Legal'},
      { type: PillValues.engineer, text: 'Manufacturing'},
      { type: PillValues.engineer, text: 'Product'},
      { type: PillValues.engineer, text: 'R&D'},
      { type: PillValues.engineer, text: 'Sales & Business Development'},
      { type: PillValues.engineer, text: 'Software Engineering'},
      { type: PillValues.engineer, text: 'Systems Engineering'},
    ],
    stage: "Series B2",
  },

  // BlocPower
  {
    name: "BlocPower",
    logo: BlocPower,
    landingPage:BlocPowerLanding,
    ogLinkShareImage:BlocPowerOG,
    location: ["New York, NY", "Remote"],
    size: "51-200 employees",
    oneLiner: "The future of urban electrification: smart, all-electric heating, cooling, and hot water systems to electrify cities",
    description: [
      "BlocPower was founded in 2014 with the mission of greening American cities. BlocPower serves building owners to electrify their properties’ heating, cooling, and hot water systems.",
      "The company has completed 1200+ projects since its inception, saving building owners 20-40% on bills a year.",
      "The company is hiring for software engineers, data engineers, finance professionals, and more.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Broad electrification of home infrastructure is critical \
                    to reducing the 40%+ of carbon emissions associated with heating \
                    and cooling. BlocPower is one of the first movers in the space \
                    and is an industry leader for electrifying cities - where over \
                    80% of Americans live.",
    investorNames: "Andreessen Horowitz, Kapor Capital, Microsoft Climate Innovation Fund",
    investorLogos: [A16Z, Kapor, Microsoft],
    jobType: "Impact Analysis",
    otherJobType: [
      'Environmental and Social Impact Analyst',
    ],
    jobPills: [
      { type: PillValues.job, text: "Environmental and Social Impact Analyst" },
    ],
    stage: "Series B",
  },

  // Lilac Solutions
  {
    name: "Lilac Solutions",
    logo: LilacSolutions,
    landingPage:LilacSolutionsLanding,
    ogLinkShareImage:LilacSolutionsOG,
    location: ["Oakland, CA"],
    size: "51-200 employees",
    oneLiner: "The future of lithium: transforming and scaling lithium extraction",
    description: [
      "To meet expanding electric vehicle demand, global lithium production will need to 30x from current levels. Lilac’s mission is to solve the lithium supply crisis to unlock the energy transition. ",
      "Lilac offers resource developers a full-service approach to lithium extraction. They design, build, and operate lithium extraction systems globally. For the balance of plant, they also partner with top engineering firms to provide infrastructure, utilities, and other process equipment.",
      "Lilac is hiring in Oakland CA, Reno NV, and South America for a variety of roles across engineering, operations, administration, and other functions. Their technical teams are looking for skill sets including process engineering, equipment design, R&D, chemical operations, field engineering, capital projects, and more. ",
      ],
    inOwnWords: false,
    goodTimeToJoin: "Transportation - both personal and commercial - must be \
                    electrified to cut global carbon emissions by ~30%. Lithium \
                    sits in the critical path of the transportation sector’s \
                    supply chain, positioning Lilac for massive growth in the \
                    coming energy transition.",
    investorNames: "LowerCarbon Capital, Breakthrough Energy Ventures",
    investorLogos: [Lowercarbon, BreakthroughEnergy],
    jobType: "IT, Finance",
    otherJobType: [
      'IT',
      'Finance',
    ],
    jobPills: [
      { type: PillValues.job, text: "IT" },
      { type: PillValues.job, text: "Finance" },
    ],
    stage: "Series C",
  },

  
  // Turntide Technologies
  {
    name: "Turntide",
    logo: Turntide,
    landingPage:TurntideLanding,
    ogLinkShareImage:TurntideOG,
    location: ["Sunnyvale, CA", "Kennesaw, GA", "Chippewa Falls, WI", "Arlington, WA", "Remote"],
    size: "501-1000 employees",
    oneLiner: "The future of energy efficiency: smart motor systems to accelerate electrification",
    description: [
      "Turntide Technologies builds motor technologies to optimize how humanity uses energy. Their mission is to eliminate the 25% of global electricity consumption that is wasted by legacy electric motors, thus accelerating the world’s transition from fossil fuels and leaving them in the ground where they belong.",
      "Electric motors are used ubiquitously in many devices, especially vehicles, heating/cooling, agriculture, and equipment for energy-intensive heavy industry. Unfortunately, last-generation motors operate inefficiently and waste a large chunk of the electricity they consume. Turntide’s Smart Motor System helps customers reduce energy usage by an average of 64% by bringing together superior device physics, intelligence, and control to optimize the efficiency.",
      "Their team culture focuses on hiring great people, asking questions and taking risks, empowering employees to challenge assumptions, and committing to a shared purpose of reducing carbon emissions.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Power production – especially for heavy industry, \
                    transportation, and heating + cooling –  is one of the \
                    largest contributors to carbon emissions; the most \
                    compelling path to limiting warming to 1.5 Celsius counts \
                    on significant reductions in electricity demand through \
                    efficiency gains. Turntide operates a win-win business \
                    where their product reduces fossil fuel demand while \
                    simultaneously generating significant cost savings for \
                    their customer: a clear recipe for growth.",
    investorNames: "Breakthrough Energy Ventures",
    investorLogos: [BreakthroughEnergy],
    jobType: "Buildings, Transport",
    otherJobType: [
      'Buildings',
      'Transport',
    ],
    jobPills: [
      { type: PillValues.job, text: "Buildings" },
      { type: PillValues.job, text: "Transport" },
    ],
    stage: "Series D",
  },

  // Redwood Materials
  {
    name: "Redwood Materials",
    logo: RedwoodMaterials,
    landingPage:RedwoodMaterialsLanding,
    ogLinkShareImage:RedwoodMaterialsOG,
    location: ["Nevada", "California", "South Carolina", "United Kingdom", "Germany"],
    size: "501-1000 employees",
    oneLiner: "The future of battery recycling: sustainably recycling lithium ion batteries",
    description: [
      "Redwood Materials was started by JB Straubel, Tesla’s co-founder and former CTO, with the mission of increasing the supply of battery materials while reducing their environmental impact and cost. The company aims to transform the battery supply chain by offering large-scale sources of domestic anode and cathode materials produced by recycling batteries.",
      "The company is growing quickly and hiring across the board, with emphasis on professionals with experience in chemistry, materials science, advanced equipment design, or automation. The culture values sustainability and allowing employees to see their hard work pay off.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Electrification of homes, transportation, and \
                    industry – a core pillar of limiting global \
                    warming to 1.5 Celsius – depends on batteries \
                    to store renewable energy to expend when supply \
                    is low. Batteries depend on the raw materials \
                    that comprise them, which Redwood provides by \
                    recycling old batteries. Redwood Materials has \
                    the founding team and the vision to realize a \
                    circular economy where battery materials are more \
                    abundantly available. They’re well positioned for \
                    explosive growth by becoming a critical dependency \
                    of a large portion of batteries manufactured in the future.",
    investorNames: "Breakthrough Energy Ventures, T. Rowe Price",
    investorLogos: [BreakthroughEnergy, TRowePrice],
    jobType: "Engineering + 3 more",
    otherJobType: [
      'Engineering',
      'Building Infrastructure',
      'Operations',
      'General & Administrative'
    ],
    jobPills: [
      { type: PillValues.engineer, text: 'Engineering'},
      { type: PillValues.engineer, text: 'Building Infrastructure'},
      { type: PillValues.job, text: 'Operations'},
      { type: PillValues.job, text: 'General & Administrative'}
    ],
    stage: "Series D",
  },

  // Pivot Bio
  {
    name: "Pivot Bio",
    logo: PivotBio,
    landingPage:PivotBioLanding,
    ogLinkShareImage:PivotBioOG,
    location: ["Berkeley, CA", "Ames, IA", "Remote"],
    size: "201-500 employees",
    oneLiner: "The future of fertilizer: nitrogen-producing microbes for farmers",
    description: [
      "Pivot Bio makes microbes that produce nitrogen – fertilizer – for farmers. Their second-generation nitrogen product is designed to increase farm productivity and deliver improved sustainability. They sell direct to grower and are a leader in synthetic nitrogen fertilizer replacement.",
      "Pivot Bio is hiring in a variety of roles in several locations across the United States.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Nitrogen fertilizers are vital for modern food production, but traditional production methods have been a major contributor to greenhouse gas emissions. Pivot Bio’s novel technology is far more sustainable than current offerings while still meeting farming demands, making them the only currently viable sustainable offering in a multi-hundred-billion dollar global market.",
    investorNames: "G2VP, DCVC, Temasek, Breakthrough Energy Ventures",
    investorLogos: [G2VP, DCVC, Temasek, BreakthroughEnergy],
    jobType: "Biz. Dev. + 5 more",
    otherJobType: [
      'Business Development',
      'Commercial Finance',
      'ESG',
      'Product Innovation',
      'Agronomy',
      'Fermentation & Formulation'
    ],
    jobPills: [
      { type: PillValues.job, text: 'Business Development'},
      { type: PillValues.job, text: 'Commercial Finance'},
      { type: PillValues.job, text: 'ESG'},
      { type: PillValues.engineer, text: 'Product Innovation'},
      { type: PillValues.engineer, text: 'Agronomy'},
      { type: PillValues.engineer, text: 'Fermentation & Formulation'}
    ],
    stage: "Series D",
  },

  // Monolith
  {
    name: "Monolith",
    logo: Monolith,
    landingPage: MonolithLanding,
    ogLinkShareImage:MonolithOG,
    location: ["Remote", "Hallam, NE", "Lincoln, NE", "Overland Park, KS"],
    size: "201-500 employees",
    oneLiner: "The future of fuel: innovative methane pyrolysis techniques to produce clean hydrogen and industrial-use carbon",
    description: [
      "Monolith’s goal is to be the world’s leading clean hydrogen and clean materials company. The company has developed methane pyrolysis technology that can be produced on a commercial scale to offer zero-emission, low-cost clean hydrogen and high-value carbon black.",
      "Monolith is working to quickly scale up its operations through its Olive Creek expansion in Nebraska, while also planning rapid expansion to serve the Clean Hydrogen, carbon black, and ammonia demands of clients across North America and around the world.",
    ],
    inOwnWords: false,
    goodTimeToJoin: "Hydrogen is an energy-rich fuel that burns clean, producing only energy and water vapor; experts agree that it represents a viable fuel alternative for major carbon-emitting transportation uses (e.g. ocean freight) and industrial uses that cannot yet be electrified. The industry is poised for rapid growth as hydrogen replaces fossil fuel for many use cases in the coming decades. Moreover, Monolith operates a win-win business where they can prevent methane from being released or flared into the atmosphere while producing valuable fuel and industrial products in the process.",
    investorNames: "TPG",
    investorLogos: [TPG],
    jobType: "Process Eng. + 6 more",
    otherJobType: [
      'Plant Operations',
      'Maintenance',
      'Technical Lab',
      'Process Engineering',
      'Accounting',
      'Commercial',
      'Supply Chain & Procurement',
    ],
    jobPills: [
      { type: PillValues.engineer, text: 'Plant Operations'},
      { type: PillValues.job, text: 'Maintenance'},
      { type: PillValues.engineer, text: 'Technical Lab'},
      { type: PillValues.engineer, text: 'Process Engineering'},
      { type: PillValues.job, text: 'Accounting'},
      { type: PillValues.job, text: 'Commercial'},
      { type: PillValues.job, text: 'Supply Chain & Procurement'},
    ],
    stage: "Beyond Series D",
  },
];

export default CompanyCards;
