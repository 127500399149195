import styled from "styled-components";
import Colors from "../../constants/colors";
type ImageProps = {
  logo: string;
};

const Container = styled.div`
  background-color: ${Colors.background};
  padding: 20px;
  display: flex;
  flex-direction: column;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  border-radius: 5px;
  margin-bottom: 25px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
    padding-bottom: 20px;
  }
`;

const HeadingContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: center;
  }
`;

const NameContainer = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 15px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-direction: row;
    align-items: end;
    margin-bottom: inherit;
  }
`;

const InfoContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    width: 65%;
    margin-left: 10px;
  }
`;

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: min-content;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-wrap: nowrap;
    > div {
      margin-bottom: 8px;
    }
  }
`;

// for investors
const InvestorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  > div {
    margin-bottom: 8px;
  }
  width: 240px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
`
// for investor logos
const InvestorImage = styled.div`
  
  background-color: ${Colors.white};
  background-image: ${(props: ImageProps) => `url(${props.logo})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 3px;
  
  height: 25px;
  width: 30px;
  margin-left: 10px;
`;

const LogoContainer = styled.div`
  height: 145px;
  width: 145px;
  margin-right: 30px;
  margin-left: 10px;
  background: ${(props: ImageProps) => `url(${props.logo})`};
  background-size: cover;
  display: none;



  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    display: flex;
  }
`;

const SmallLogo = styled.div`
  background: ${(props: ImageProps) => `url(${props.logo})`};
  background-size: cover;
  height: 35px;
  width: 35px;
  display: flex;
  margin-right: 10px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    display: none;
  }
`;

const ButtonContainer = styled.div`
  display: flex;
  text-align: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: auto;
  }
`;

const CardTitle = styled.p`
  font-family: Oswald-Regular;
  font-size: 26px;
  color: ${Colors.text};
  margin: 0px 20px 0px 0px;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    font-size: 32px;
    overflow: hidden;
    white-space: nowrap;
  }
`;

const HoverTooltip = styled.div`
  font-family: SplineSansMono-Regular;
  font-size: 16px;
  margin: 0px 20px 0px 0px;
`;

const TextContainer = styled.div``;

export {
  Container,
  InfoContainer,
  PillContainer,
  InvestorContainer,
  InvestorImage,
  LogoContainer,
  HeadingContainer,
  ButtonContainer,
  CardTitle,
  TextContainer,
  NameContainer,
  SmallLogo,
  HoverTooltip,
};
