import styled from "styled-components";
type ImageProps = {
  image: string;
};

const HeadingContainer = styled.div`
  margin-top: 10%;
  margin-bottom: 5%;
`;


// Contains the full list
const ListContainer = styled.div`
  display:flex;
  flex-direction: column;
  align-items: center;

`;


// Contains each individual list item
// Which features a bulleted list and (optionally) a graph
const ListItem = styled.div`
  width:100%;
  
  // for smaller screens
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
`;

// Contains the bulleted list and a heading
const ReasonContainer = styled.div`
  // for smaller screens

  // landscape phones, 576px and up
  @media (min-width: 576px) {
    width: 800px;
  }
`;

const GraphImage = styled.div`
  background: ${(props: ImageProps) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  // portrait phones
  width: 100%;
  height: 0;
  padding-top: 66.64%;


  // landscape phones, 576px and up
  @media (min-width: 576px) {
    width: 600px;
    height: 394px;
    padding-top: 0;
  }
`;

export { 
  HeadingContainer,
  ListContainer,
  ListItem,
  ReasonContainer,
  GraphImage,
};