import styled from "styled-components";
import { CircleDiv, H1 } from "../../components/styled";
import Colors from "../../constants/colors"
type ImageProps = {
  image: string;
};

const PillContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 5px;
`;

const Logo = styled.div`
  background: ${(props: ImageProps) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  height: 50px;
  width: 50px;
  display: flex;
`;

const Socials = styled.div`
  display: flex;
  margin: 30px auto;
`;

const ContentContainer = styled.div`
  margin-top: 3%;
  margin-bottom: 5%;
  > h1 {
    text-align: left;
    margin-top: 0;
    font-weight: 300;
    text-transform: none;
  }

  // landscape phones, 576px and up
  @media (min-width: 576px) {
    width: 800px;
  }
`;

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const InternalNavContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-top: 5%;
`;

const SocialIcon = styled.img`
  height: 20px;
  width: auto;
  margin-right: 15px;
`;

const InlinePreviewImage = styled.div`
  background: ${(props: ImageProps) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 3px;
  margin: 15px auto 25px auto;

  // For vertical smartphones
  width: 302px;
  height: 126px;

  // Landscape phones and larger
  @media (min-width: 576px) {
    width: 800px;
    height: 333px;
  }
`;

const ProfileContent = styled.div`
  width: 100%;
  display: flex;
`;

const NameContainer = styled.div`
  // For vertical mobile phones
  width: 100%;
  display: flex;
  flex-direction: column;

  align-items: baseline;
  margin-bottom: 20px;

  // for the apply button
  > button {
    margin-top: 10px;
    width: 100%;
  }


  // Landscape phones, 576px and up
  @media (min-width: 576px) {
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    > button {
      width: auto;
    }
  }
`;

const Name = styled(H1)`
  font-family: Oswald-Regular;
  text-transform: none;
  margin: 0;
  margin-right: 20px;

  // Large devices (desktops, 1398px and up)
  @media (min-width: 1398px) {
    overflow: hidden;
    white-space: nowrap;
  }
`;

const InvestorWrapper = styled.div`
  margin: 20px auto;
  display: flex;
  flex-direction: column;
`;

const InvestorContainer = styled.div`
  display: flex;
  flex-wrap: wrap;

  align-items: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    flex-wrap: nowrap;
  }
  > p {
    margin: 0;
    margin-right: 10px;
  }
`;

const InvestorLogo = styled.div`
  background-color: ${Colors.white};
  background-image: ${(props: ImageProps) => `url(${props.image})`};
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  border-radius: 3px;
  
  height: 30px;
  width: 50px;
  margin-right: 10px;
`;


export {
  PillContainer,
  NameContainer,
  Logo,
  Socials,
  ContentContainer,
  PageContainer,
  InternalNavContainer,
  SocialIcon,
  InlinePreviewImage,
  ProfileContent,
  Name,
  InvestorWrapper,
  InvestorContainer,
  InvestorLogo,
};
