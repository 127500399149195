const Colors = {
  text: "#01072B",
  background: "#F1F1F1",
  lightBlue: "#D8DEFD",
  purple: "#6E72BE",
  orange: "#EE964B",
  white: "#fff",
  green: "#A6BB3A",
};

export default Colors;
