import { NavLink } from "react-router-dom";
import styled from "styled-components";
import Colors from "../../constants/colors";
import { Wrapper } from "../styled";
import { animated } from "react-spring";

type Props = {
  isDarkMode: boolean;
};

const NavLogo = styled.h1`
  font-family: Oswald-Bold;
  margin-top: 0.5em;
  font-size: 25px;
  color: ${(props: Props) =>
    props.isDarkMode ? `${Colors.background}` : `${Colors.text}`};
  text-transform: uppercase;
  text-align: center;
`;

const NavItemContainer = styled.div`
  height: 25px;
  display: flex;
  align-items: center;
`;

const NavItem = styled.h2`
  font-family: SplineSansMono-Regular;
  margin-top: 0.5em;
  font-size: 16px;
  color: ${(props: Props) =>
    props.isDarkMode ? `${Colors.background}` : `${Colors.text}`};
  text-align: center;

  &:hover {
    cursor: pointer;
    border-bottom: ${(props: Props) =>
      props.isDarkMode
        ? `2px dashed ${Colors.background};`
        : `2px dashed ${Colors.text};`};
    padding-bottom: 0px;
  }
`;

const MobileNavItem = styled.h2`
  font-family: SplineSansMono-Regular;
  width: fit-content;
  margin: auto;
  margin-top: 1.5em;
  font-size: 25px;
  color: ${(props: Props) =>
    props.isDarkMode ? `${Colors.background}` : `${Colors.text}`};
  text-align: center;
`;

const MainNavItemGroup = styled.div`
  width: 50%;
  justify-content: space-between;
  flex-direction: row;
  display: none;

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    display: flex;
    width: 40%;
  }
`;

const MobileNavItemGroup = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  text-align: center;
  flex-direction: column;
  margin-top: 50px;
`;

const NavContainer = styled.div`
  display flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 1em;
`;

const LinkElem = styled(NavLink)`
  text-decoration: none;
  color: ${(props: Props) =>
    props.isDarkMode ? `${Colors.background}` : `${Colors.text}`};
  &.active {
    > h2 {
      border-bottom: ${(props: Props) =>
        props.isDarkMode
          ? `2px dashed ${Colors.background};`
          : `2px dashed ${Colors.text};`};
    }
  }
`;

const BackgroundColor = styled.div`
  width: 100%;
  z-index: 1;
  background: ${(props: Props) =>
    props.isDarkMode ? `${Colors.text}` : `none`};
`;

const MobileNav = styled.div`
  display: flex;
  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    display: none;
  }
`;

const Hamburger = styled.div`
  width: 30px;
  display: flex;
  &:hover {
    cursor: pointer;
  }
`;

const Icon = styled.img`
  height: 100%;
  width: 100%;

  filter: ${(props: Props) =>
    props.isDarkMode
      ? `invert(92%) sepia(62%) saturate(7426%) hue-rotate(182deg) brightness(123%) contrast(88%);`
      : "none"};
`;

const NavScreen = styled(animated.div)`
  height: 100%;
  width: 100%;
  position: fixed;
  overflow-y: hidden;
  z-index: 1;
  top: 0;
  left: 0;
  background: ${(props: Props) =>
    props.isDarkMode ? `${Colors.text}` : `${Colors.lightBlue}`};
  color: ${(props: Props) =>
    props.isDarkMode ? `${Colors.background}` : `${Colors.text}`};

  ${Wrapper} > * {
    overflow: hidden;
  }
`;

const MobileNavContent = styled.div`
  position: absolute;
  top: 50%;
`;

export {
  NavLogo,
  NavItem,
  MobileNavItem,
  NavItemContainer,
  MainNavItemGroup,
  MobileNavItemGroup,
  NavContainer,
  LinkElem,
  BackgroundColor,
  MobileNav,
  Hamburger,
  NavScreen,
  Icon,
  MobileNavContent,
};
