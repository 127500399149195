import styled from "styled-components";
import Colors from "../../constants/colors";
import { animated } from "react-spring";
import { Line } from "../styled";

interface Props {
  isOpen?: boolean;
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: #fff;
  border-radius: 3px;
  padding: 10px 25px;
  margin-bottom: 30px;

  &:hover {
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);
  }

  ${(props: Props) =>
    props.isOpen
      ? "box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.11);"
      : "box-shadow: none;"};

  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    padding: 15px 50px;
  }
`;

const Icon = styled.img`
  height: 25px;
  width: auto;
`;

const Content = styled(animated.div)`
  border: none;
  ${(props: Props) =>
    props.isOpen
      ? `border-top: 2px dashed ${Colors.text};`
      : "border-top: none;"}
  color: ${Colors.text};
  width: 100%;
  overflow: hidden;
`;

const Heading = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  > h3 {
    margin-bottom: 0.5em;
    margin-right: 15px;
  }
  &:hover {
    cursor: pointer;
  }
`;

const ContentContainer = styled.div`
  padding-bottom: 8px;
  padding-top: 5px;

  > ${Line} {
    padding-bottom: 5px;
  }
`;

export { Container, Icon, Content, Heading, ContentContainer };
