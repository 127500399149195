import styled from "styled-components";
import Colors from "../../constants/colors";

interface Props {
  isAccent?: boolean;
}
const Icon = styled.img`
  height: 12px;
  width: auto;
`;
const Container = styled.div<Props>`
  background-color: ${(props) =>
    props.isAccent ? Colors.purple : Colors.white};
  border-radius: 3px;
  padding: 5px 9px 4px 6px;
  display flex;
  align-items: center;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow-x: clip;
  height: auto;
  min-height: 18px;
  overflow-wrap: anywhere;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
`;
const PillText = styled.p<Props>`
  font-family: SplineSansMono-Regular;
  font-size: 14px;
  font-weight: 100;
  color: ${(props) => (props.isAccent ? Colors.white : Colors.text)};
  margin: 0 0 0 5px;
`;

const TextContainer = styled.div`
  width: max-content;
`;

export { Container, PillText, Icon, TextContainer };
