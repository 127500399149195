import styled from "styled-components";
import { Wrapper } from "../../components/styled";
import Colors from "../../constants/colors"

const SubheaderContainer = styled.div`
  // Small devices (landscape phones, 576px and up)
  @media (min-width: 576px) {
    margin-left: auto;
    margin-right: auto;
  }

  // Medium devices (tablets, 768px and up)
  @media (min-width: 768px) {
    margin: 0 100px 0 100px;
  }

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: 0 150px 0 150px;
  }

  // Extra large devices (large desktops, 1200px and up)
  @media (min-width: 1200px) {
    margin: 0 200px 0 200px;
  }
`;

const HeadingJobsPill = styled.div`
  background-color: ${Colors.purple};
  color: ${Colors.white};
  border-radius: 3px;
  padding: 5px 9px 4px 6px;
  display: inline-block;
  align-items: center;
  width: fit-content;
  margin-right: 15px;
  margin-bottom: 15px;
  overflow-x: clip;
  height: auto;
  min-height: 18px;
  overflow-wrap: anywhere;
  margin-top: 7.5px;
  margin-bottom: 7.5px;
`;

const PillContainer = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin: 50px 150px 0 150px;
  }
`;

const LandingContainer = styled.div`
  margin-top: 5%;
`;

const StartGradient = styled.div`
  background: linear-gradient(180deg, #f1f1f1 40.99%, #eaecf4 100%);
  padding-bottom: 5%;
`;

const EaseInGradient = styled.div`
  background: linear-gradient(180deg, #eaecf4 0%, #d8defd 100%);
  padding-bottom: 25px;
  ${Wrapper} > :last-child {
    margin-bottom: 0;
  }
`;

const SolidGradient = styled.div`
  background-color: #d8defd;
  padding-bottom: 25px;
  ${Wrapper} > :last-child {
    margin-bottom: 0;
  }
`;

const EaseOutGradient = styled.div`
  background: linear-gradient(180deg, #d8defd 0%, #e2e6f8 100%);
  padding-bottom: 6px;
  ${Wrapper} > :last-child {
    margin-bottom: 0;
  }
`;

const EndGradient = styled.div`
  background: linear-gradient(180deg, #e2e6f8 0%, #f1f1f1 100%);
  padding-top: 12%;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    padding-top: 5%;
  }
`;

const StatementContainer = styled.div`
  margin-bottom: 5%;
`;

export {
  SubheaderContainer,
  HeadingJobsPill,
  PillContainer,
  LandingContainer,
  StartGradient,
  EaseInGradient,
  EaseOutGradient,
  SolidGradient,
  EndGradient,
  StatementContainer,
};
