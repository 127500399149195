import Card from "../../components/card/card";
import Pill, { PillValues } from "../../components/pill/pill";
import { H1, H2, Wrapper } from "../../components/styled";
import {
  SubheaderContainer,
  PillContainer,
  HeadingJobsPill,
  LandingContainer,
  StartGradient,
  EaseInGradient,
  SolidGradient,
  EaseOutGradient,
  EndGradient,
  StatementContainer,
} from "./landing.styles";
import TitleMultilineTextBlock from "../../components/title-multiline-text-block/titleMultilineTextBlock";
import CompanyHighlight from "../../components/company-highlight/companyHighlight";
import CTA from "../../components/cta/cta";
import Modal from "../../components/modal/modal";
import SubstackForm from "../../components/substack-form/substackForm";
import { PrimaryButton } from "../../components/button/button";
import QuoteCarousel from "../../components/quote-carousel/quoteCarousel";
import { Waypoint } from "react-waypoint";
import { useState } from "react";
import CompanyCards from "../../constants/company-cards";
import Quotes from "../../constants/quotes";
import ListHighlightCompanies from "../../constants/list-highlight-companies";
import {Helmet} from "react-helmet"; // for OpenGraph and Page Titles
import PreviewFrame from "../../images/PreviewFrame.png";
import Typewriter from 'typewriter-effect';
import ReactGA from "react-ga4"
import {useLocation} from "react-router-dom"

          
function Landing() {
  // Google Analytics
  ReactGA.send({ hitType: "pageview", page: useLocation().pathname });

  // For track record section
  const [startCounterAnim, setStartCountAnim] = useState(false);

  // for the typewriter at the top
  const AllJobRoles = [
    'Hardware Engineering',
    'HR & People',
    'IT & Security',
    'Manufacturing Eng.',
    'R&D',
    'Sales & BD',
    'Software Engineering',
    'Systems Engineering',
    'Accounting & Finance',
    'BizOps',
    'Data Engineering',
    'Marketing & Comms',
    'Construction',
    'Design',
    'Data Science',
    'Analytics',
    'Partnerships',
    'Legal',
    'QA',
    'Supply Chain',
    'Product',
    'Energy Services'
  ]

  // for the list of companies
  const listCompanies = CompanyCards.slice();
  const beginningList = [];
  const endList = [];
  switch (listCompanies.length) {
    case 0:
    case 1:
      // must have a least two items
      break;
    case 2:
      beginningList.push(listCompanies[0]);
      endList.push(listCompanies[1]);
      break;
    default:
      // These items will be wrapped with a ease in to the gradient.
      // Add more to the list to make the ease longer
      beginningList.push(listCompanies[0]);
      endList.push(listCompanies[listCompanies.length - 1]);
      listCompanies.shift();
      listCompanies.pop();
      // The rest of the list will have the solid
      // background as the center of the gradient fade
      break;
  }

  // for the email list modal
  const [modal, setModal] = useState(false);
  const ToggleModal = () => setModal(!modal);

  return (
    <>
      <Helmet>
        <title>Future List: Join Top Climate Companies</title>
        <meta property="og:title" content="Future List: Join Top Climate Companies" />
        <meta property="og:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png" />
        <meta property="og:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta property="og:url" content="/" />

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content="@future_list_co" />
        <meta name="twitter:title" content="Future List: Join Top Climate Companies" />
        <meta name="twitter:description" content="High-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities." />
        <meta name="twitter:image" content="https://futurelist-preview-assets.web.app/PreviewFrame.png" />
      </Helmet>
      <StartGradient>
        <Wrapper>
          <LandingContainer>
            <H1>
              Discover top climate companies to join.
            </H1>
            <SubheaderContainer>
              <H2>
                We identify high-growth, high-impact climate ventures
                tackling trillion-dollar market opportunities.
              </H2>
              <H2>
                Hiring for:<br />
                <HeadingJobsPill>
                  <Typewriter
                    options={{
                      strings: AllJobRoles,
                      delay: 40,
                      autoStart: true,
                      loop: true,
                    }}
                  />
                </HeadingJobsPill>
              </H2>
            </SubheaderContainer>
          </LandingContainer>
        </Wrapper>
      </StartGradient>
      <div>
        <EaseInGradient>
          <Wrapper>
            {beginningList.map((c, i) => {
              return <Card {...c} key={i} />;
            })}
          </Wrapper>
        </EaseInGradient>
        <SolidGradient>
          <Wrapper>
            {listCompanies.length !== 0 &&
              listCompanies.map((c, i) => {
                return <Card {...c} key={i} />;
              })}
          </Wrapper>
        </SolidGradient>
        <EaseOutGradient>
          <Wrapper>
            {endList.map((c, i) => {
              return <Card {...c} key={i} />;
            })}
          </Wrapper>
        </EaseOutGradient>
      </div>
      <EndGradient>
        <Wrapper>
          <LandingContainer>
            <StatementContainer>
              <H1>
                The iconic companies of the future <br /> will be the ones that
                built it.
              </H1>
            </StatementContainer>
          </LandingContainer>
          <TitleMultilineTextBlock
            title={
              "Climate change is the single largest market opportunity this century."
            }
            textLines={[
              <p>Climate change is driven by energy and food production and use.</p>,
              <p>These are the largest markets in human history, totaling <span style={{ fontWeight: "bold" }}>trillions of dollars in aggregate spend.</span></p>,
              <p>Solving climate <span style={{ fontWeight: "bold" }}>requires innovation</span> within those markets.</p>,
              <p>Joining a company that’s early to an enormous market gives you <span style={{ fontWeight: "bold" }}>rare opportunity</span> for outsized learning, career growth, and financial upside.</p>,
            ]}
            footer={
              ""
              // <PrimaryButton 
              //   text={"Drop Youe Résumé"} 
              //   onClick={() =>
              //     {window.open(
              //       "https://airtable.com/shr8x0ktLIQrvAAJ8",
              //       '_blank', 'noopener,noreferrer')
              //     }
              //   }
              // />
            }
          />
          
        <QuoteCarousel quotes={Quotes} />
        </Wrapper>
      </EndGradient>
      <Wrapper>
        <CTA
          callToAction="Want Updates on Companies and Jobs?"
          buttonText="Join the Newsletter"
          onClick={ToggleModal}
        />
        <Modal 
          show={modal} 
          title="Sign up for updates"
          close={ToggleModal}
          children={
            <SubstackForm />
          }
        />
      </Wrapper>
      <Wrapper>
        <Waypoint onEnter={() => setStartCountAnim(true)} />
        <CompanyHighlight
          title={"Companies previously selected for Future List"}
          companies={ListHighlightCompanies}
          startAnimation={startCounterAnim}
        />
        {/*<CTA
          callToAction="Know a Company that Belongs on the List?"
          buttonText="Nominate a Company"
          onClick={() =>
            {window.open(
              "https://airtable.com/shr4Dw57UCeu9UHfA",
              '_blank', 'noopener,noreferrer')
            }
          }
        />*/}
      </Wrapper>
    </>
  );
}

export default Landing;
