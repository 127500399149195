import {SubstackIframe} from './substackForm-styles';
import Colors from "../../constants/colors"

const SubstackForm = () => {
	return (
		<SubstackIframe 
			src="https://futurelist.substack.com/embed" 
			frameBorder="0" 
			scrolling="no"
		/>
	);
}

export default SubstackForm;