import Pill, { PillProps } from "../pill/pill";
import { Text, TextBold, Line } from "../styled";
import { SecondaryButton } from "../button/button";
import {
  Container,
  InfoContainer,
  PillContainer,
  InvestorContainer,
  InvestorImage,
  LogoContainer,
  HeadingContainer,
  NameContainer,
  ButtonContainer,
  CardTitle,
  TextContainer,
  SmallLogo,
  HoverTooltip,
} from "./card.styles";
import { Link } from 'react-router-dom';
import {Tooltip} from 'react-tooltip-z';



interface CardProps {
  name: string;
  logo: string;
  landingPage: string;
  ogLinkShareImage: string;
  location: string[];
  size: string;
  oneLiner: string;
  description: string[];
  inOwnWords: boolean;
  goodTimeToJoin: string;
  investorNames: string;
  investorLogos: string[];
  jobType: string;
  otherJobType: string[];
  jobPills: PillProps[];
  stage: string;
}

const Card = ({
  name,
  logo,
  location,
  landingPage,
  ogLinkShareImage,
  size,
  oneLiner,
  description,
  inOwnWords,
  goodTimeToJoin,
  investorNames,
  investorLogos,
  jobType,
  otherJobType,
  jobPills,
  stage,
}: CardProps) => {

  // for investor tooltip
  const investorTooltipLines = investorNames.split(", ");
  const investorTooltipContent = {
    tooltipContent: <>
                      <HoverTooltip>
                      {investorTooltipLines.map((l: string, i: number) => {
                        return <>{l}<br/></>
                      })}
                      </HoverTooltip>
                    </>,
    tooltipClassName: "investor-tooltip",
    limitWidth: false,
  }

  // for investor tooltip
  const jobTooltipContent = {
    tooltipContent: <>
                      <HoverTooltip>
                      {otherJobType.map((l: string, i: number) => {
                        return <>{l}<br/></>
                      })}
                      </HoverTooltip>
                    </>,
    tooltipClassName: "investor-tooltip",
    limitWidth: false,
  }

  return (
    <Container>
      <LogoContainer logo={logo}/>
      <InfoContainer>
        <HeadingContainer>
          <NameContainer>
            <SmallLogo logo={logo}/>
            <CardTitle>{name}</CardTitle>
          </NameContainer>
          <PillContainer>
            <Pill type="size" text={size} />
            {(() => {
              
              // check if we should truncate or not

              if (location.length <= 2){ // only 2 elements, no truncation
                return location?.map((l: string, i: number) => {
                    return <Pill type="location" key={i} text={l} />;
                }) 

              } else {
                 var arr = location?.slice(0,2).map((l: string, i: number) => {
                  return <Pill type="location" text={l} />;
                });
                const remnants = location.length - 2;
                arr.push(<Pill type="location" text={"+ " + remnants + " more"} />);
                return arr;
              }
            })()}
          </PillContainer>
        </HeadingContainer>
        <Text>{oneLiner}</Text>
        <Tooltip {...investorTooltipContent}>
          <InvestorContainer>
            <TextBold>Investors</TextBold>
            {investorLogos?.map((l: string, i: number) => {
                return <InvestorImage key={i} logo={l} />;
            })}
          </InvestorContainer>
        </Tooltip>
        <Line />
        <Tooltip {...jobTooltipContent}>
          <PillContainer>
            <Pill type="job" text={jobType} />
            <Pill type="stage" text={stage} />
          </PillContainer>
        </Tooltip>
      </InfoContainer>
      <ButtonContainer>
        <Link 
          to={"profile/"+name.replace(/\s/g, "").toLowerCase()} 
          target="_blank"
          style={{ textDecoration: 'none' }}
        >
          <SecondaryButton text="View Profile" />
        </Link>
      </ButtonContainer>
    </Container>
  );
};

export default Card;
