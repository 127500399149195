import styled from "styled-components";

const Container = styled.div`
  margin: auto;
  margin-top: 15%;
  margin-bottom: 30%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  // Large devices (desktops, 992px and up)
  @media (min-width: 992px) {
    margin-bottom: 15%;
  }
`;

export { Container };
